import { AppState } from "..";

const getUser = (state: AppState) => state.user;

export const getEditPasswordError: (state: AppState) => boolean = state =>
  getUser(state).editPasswordError;

export const getEditPasswordLoading: (state: AppState) => boolean = state =>
  getUser(state).editPasswordLoading;

export const getHasLoginError: (state: AppState) => boolean = state => getUser(state).hasLoginError;

export const getResetPasswordError: (state: AppState) => boolean = state =>
  getUser(state).resetPasswordError;

export const getResetPasswordLoading: (state: AppState) => boolean = state =>
  getUser(state).resetPasswordLoading;

export const getUserCountryCode: (state: AppState) => string = state => getUser(state).countryCode;

export const getUserLoggedIn: (state: AppState) => boolean = state =>
  !!getUser(state).token && !!getUser(state).username;

export const getUsername: (state: AppState) => string = state => getUser(state).username;

export const getUserPrivacyPolicy: (state: AppState) => boolean = state =>
  getUser(state).hasAcceptedPrivacyPolicy;
