import React from "react";
import { DetailText, SmallText } from "@cf-design-system/typography";
import Cluster, { EClusterType } from "./Cluster";
import "./map.scss";

interface IProps {
  content: Record<string, string>;
}

const Legend: React.FC<IProps> = ({ content }) => (
  <ul className="legend">
    <li className="legend-item">
      <SmallText>{`${content.common_daysLeft}:`}</SmallText>
    </li>
    <li className="legend-item">
      <Cluster type={EClusterType.danger} />
      <DetailText>{content.common_clusterDanger}</DetailText>
    </li>
    <li className="legend-item">
      <Cluster type={EClusterType.warning} />
      <DetailText>{content.common_clusterWarning}</DetailText>
    </li>
    <li className="legend-item">
      <Cluster />
      <DetailText>{content.common_clusterDefault}</DetailText>
    </li>
  </ul>
);

export default Legend;
