export const DEFAULT_LANG = "en-GB";
export const REQUEST_CONTENT = "content/REQUEST_CONTENT";
export const REQUEST_CONTENT_ERROR = "content/REQUEST_CONTENT_ERROR";
export const REQUEST_CONTENT_SUCCESS = "content/REQUEST_CONTENT_SUCCESS";
export const SET_LANGUAGE = "content/SET_LANGUAGE";

export interface IParsedString {
  [key: string]: string;
}

export interface ITranslatableString {
  fields: {
    content?: { [key: string]: string };
    contentLong?: { [key: string]: string };
    stringKey: { [key: string]: string };
  };
}

export interface IContentPage {
  fields: {
    pageStrings: { [key: string]: ITranslatableString[] };
    pageId: { [key: string]: string };
  };
}

export interface IContentLanguage {
  fields: {
    languageCode: { [key: string]: string };
    languageName: { [key: string]: string };
  };
}

export interface IApiContentEntry {
  fields: {
    [key: string]: Record<string, unknown>;
  };
  sys: {
    contentType: string;
    id: string;
    type: string;
  };
}

export interface IContentTypeEntries {
  [key: string]: IApiContentEntry[] | IContentLanguage[] | IContentPage[];
}

export interface IApiContent {
  assets: IApiContentEntry[];
  contentfulToken: string | null;
  dbTimestamp: number;
  entries: IApiContentEntry[];
}

export interface IApiContentSync {
  contentfulToken: string;
  dbTimestamp: number | null;
}

export interface IPageEntries {
  [key: string]: Record<string, string>;
}

export type IEntries = IPageEntries;

export interface IMappedEntries extends IEntries {
  account: Record<string, string>;
  alarms: Record<string, string>;
  farmList: Record<string, string>;
  footer: Record<string, string>;
  header: Record<string, string>;
  language: Record<string, string>;
  login: Record<string, string>;
  privacyPolicy: Record<string, string>;
  siloDetails: Record<string, string>;
  siloFeed: Record<string, string>;
  siloInfo: Record<string, string>;
  siloList: Record<string, string>;
  siloMenu: Record<string, string>;
}

export interface ILanguageEntries {
  [language: string]: IEntries;
}

export interface ILanguage {
  code: string;
  name: string;
}

export interface IContent {
  entries: ILanguageEntries;
  languages: ILanguage[];
}

export interface IContentState {
  contentError: boolean;
  contentfulToken: string;
  contentLoading: boolean;
  currentLanguage: string;
  dbTimestamp: number | null;
  entries: ILanguageEntries;
  languages: ILanguage[];
}

export interface IRequestContent {
  type: typeof REQUEST_CONTENT;
}

export interface IRequestContentError {
  type: typeof REQUEST_CONTENT_ERROR;
}

export interface IRequestContentSuccess {
  type: typeof REQUEST_CONTENT_SUCCESS;
  payload: {
    contentfulToken: string | null;
    dbTimestamp: number;
    entries: ILanguageEntries;
    languages: ILanguage[];
  };
}

export interface ISetLanguage {
  type: typeof SET_LANGUAGE;
  payload: {
    language: string;
  };
}

export type ContentActionTypes =
  | IRequestContent
  | IRequestContentError
  | IRequestContentSuccess
  | ISetLanguage;
