import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { Icon } from "@cf-design-system/icon";
import { Link } from "@cf-design-system/link";
import { H1, SubHeading, Text } from "@cf-design-system/typography";
import Routes from "../../routes";
import { getCurrentContent } from "../../store/content/selectors";
import { requestSilo } from "../../store/farm/actions";
import { getSelectedSilo, getSelectedSiloId, getSelectedFarm } from "../../store/farm/selectors";
import { getMissingDaysIndicatorColor } from "../map/helpers";
import { fillMissingDays, getChartData, getFillData } from "./helpers";
import Silo from "./Silo";
import SiloAlarms from "./SiloAlarms";
import SiloConsumptionChart, { SEVEN_DAYS_MS } from "./SiloConsumptionChart";
import PageHeader from "../header/PageHeader";
import "./siloDetails.scss";

const SiloDetails: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { siloDetails: content, siloMenu: menuContent } = useSelector(getCurrentContent);
  const selectedSiloId = useSelector(getSelectedSiloId);
  const silo = useSelector(getSelectedSilo);
  const farm = useSelector(getSelectedFarm);

  const loadedSilo = useMemo(() => !!silo, [silo]);

  const handleInfoClick = () => {
    history.push(Routes.siloInfo);
  };

  useEffect(() => {
    if (!selectedSiloId) {
      history.replace(Routes.silos);
    } else if (loadedSilo) {
      dispatch(requestSilo(selectedSiloId, SEVEN_DAYS_MS));
    }
  }, [dispatch, history, selectedSiloId, loadedSilo]);

  const siloWeight = useMemo(() => ((silo?.weight || 0) / 1000).toFixed(1), [silo]);

  const lastMeasureWeight = useMemo(
    () => ((silo?.lastMeasure.weight || 0) / 1000).toFixed(1),
    [silo]
  );

  const lastReading = useMemo(
    () =>
      silo?.lastMeasure
        ? new Date(silo.lastMeasure.readingTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit"
          })
        : "",
    [silo]
  );

  const missingDays = useMemo(
    () =>
      silo?.missingDaysNr != null
        ? `${silo.missingDaysNr} ${content.common_daysLeft.toLocaleLowerCase()}`
        : content.common_unknownDaysLeft,
    [content.common_daysLeft, content.common_unknownDaysLeft, silo]
  );

  const fillData = useMemo(
    () => (silo?.measures ? getFillData(silo.measures, silo.weight) : undefined),
    [silo]
  );

  const chartData = useMemo(
    () => (silo?.measures ? fillMissingDays(getChartData(silo.measures)) : []),
    [silo]
  );

  const avgConsumption = useMemo(() => ((silo?.averageConsumption || 0) / 1000).toFixed(1), [silo]);

  return (
    <section className="silo-details">
      <div className="silo-details-header">
        <PageHeader
          backRoute={Routes.silos}
          title={`Silo ${silo?.number || ""}`}
          subTitle={farm?.name}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link className="button-edit-info" onClick={handleInfoClick}>
          {menuContent.siloMenu_editInfo}
        </Link>
      </div>
      <BackgroundCard className="silo-details-body">
        {silo && (
          <div key={silo.number} className="silo-details-info">
            <div className="silo-details-info-left">
              <Silo silo={silo} />
              <div className="silo-details-measures">
                <H1 className="silo-details-volume-pct">{silo?.capacityPct}%</H1>
                <SubHeading>
                  {lastMeasureWeight} / {siloWeight} {content.common_tons}
                </SubHeading>
                <Text
                  className={`silo-details-days-left ${getMissingDaysIndicatorColor(
                    silo?.missingDaysNr
                  )}`}
                >
                  {missingDays}
                </Text>
              </div>
            </div>
            <div className="silo-details-info-right">
              <span className="silo-details-info-item">
                <Icon
                  frameClassName="silo-details-info-item-icon"
                  frameColor="#fbfafa"
                  hasFrame
                  name="barChart"
                  fill="var(--color-black)"
                />
                <Text>
                  {`${content.siloDetails_averageTons}`.replace("%tons%", `${avgConsumption}`)}
                </Text>
              </span>
              <span className="silo-details-info-item">
                <Icon
                  frameClassName="silo-details-info-item-icon"
                  frameColor="#fbfafa"
                  hasFrame
                  name="clock"
                  fill="var(--color-black)"
                />
                <Text>
                  {content.siloDetails_lastMeasured} {lastReading}
                </Text>
              </span>
              {silo?.animal && (
                <span className="silo-details-info-item">
                  <Icon
                    frameClassName="silo-details-info-item-icon"
                    frameColor="#fbfafa"
                    hasFrame
                    name="clipboard"
                    fill="var(--color-black)"
                  />
                  <Text>
                    {silo?.animalQuantity} {silo && content[`common_${silo.animal.name}`]}
                  </Text>
                </span>
              )}
              {silo?.feed.name && (
                <span className="silo-details-info-item">
                  <Icon
                    frameClassName="silo-details-info-item-icon"
                    frameColor="#fbfafa"
                    hasFrame
                    name="tag"
                    fill="var(--color-black)"
                  />
                  <Text>{silo.feed.name}</Text>
                </span>
              )}
              {silo?.feed.density && (
                <span className="silo-details-info-item">
                  <Icon
                    frameClassName="silo-details-info-item-icon"
                    frameColor="#fbfafa"
                    hasFrame
                    name="grid"
                    fill="var(--color-black)"
                  />
                  <Text>{silo.feed.density.toFixed()} kg/m³</Text>
                </span>
              )}
            </div>
          </div>
        )}
        <Divider className="silo-details-divider" />
        {chartData.length > 0 && (
          <SiloConsumptionChart silo={silo} chartData={chartData} fillData={fillData} />
        )}
        <Divider className="silo-details-divider" />
        <SiloAlarms />
      </BackgroundCard>
    </section>
  );
};

export default SiloDetails;
