import { IAnimal } from "../animal/types";
import { IFeed } from "../feed/types";

export const EDIT_SILO_FEED = "farm/EDIT_SILO_FEED";
export const EDIT_SILO_FEED_ERROR = "farm/EDIT_SILO_FEED_ERROR";
export const EDIT_SILO_FEED_SUCCESS = "farm/EDIT_SILO_FEED_SUCCESS";
export const EDIT_SILO_INFO = "farm/EDIT_SILO_INFO";
export const EDIT_SILO_INFO_ERROR = "farm/EDIT_SILO_INFO_ERROR";
export const EDIT_SILO_INFO_SUCCESS = "farm/EDIT_SILO_INFO_SUCCESS";
export const REQUEST_FARMS = "farm/REQUEST_FARMS";
export const REQUEST_FARMS_ERROR = "farm/REQUEST_FARMS_ERROR";
export const REQUEST_SILO_LIST_ERROR = "farm/REQUEST_SILO_LIST_ERROR";
export const REQUEST_FARMS_SUCCESS = "farm/REQUEST_FARMS_SUCCESS";
export const REQUEST_SILO = "farm/REQUEST_SILO";
export const REQUEST_SILO_LIST = "farm/REQUEST_SILO_LIST";
export const REQUEST_SILO_LIST_SUCCESS = "farm/REQUEST_SILO_LIST_SUCCESS";
export const REQUEST_SILO_SUCCESS = "farm/REQUEST_SILO_SUCCESS";
export const SELECT_FARM = "farm/SELECT_FARM";
export const SELECT_SILO = "farm/SELECT_SILO";

export interface ISiloDayConsumptionData {
  dateString: string;
  date: Date;
  timeString: string;
  weight: number;
}

export interface IOrder {
  feedId: string | null;
  deliveryDate: string;
  orderId: string;
  productName?: string;
  productNumber?: string;
  rawSiloNumber: string;
  weight: number;
}

export interface IMeasure {
  createDateTime: string;
  height: number;
  id: string;
  readingTime: string;
  volume: number;
  weight: number;
}

export interface ISilo {
  animal: IAnimal | null;
  animalQuantity: number | null;
  averageConsumption: number;
  capacity: number;
  capacityPct?: number;
  createDateTime: string;
  digitplanName: string;
  feed: IFeed;
  height: number;
  id: string;
  lastMeasure: IMeasure;
  lastPredictedMeasure: IMeasure;
  latitude: string;
  longitude: string;
  measures: IMeasure[];
  missingDaysNr?: number | null;
  name: string;
  number: string; // can either be "number" or "number1, number2"
  predictedMeasures: IMeasure[];
  serial: string;
  weight: number;
}

export interface IFarm {
  address: string;
  createDateTime: string;
  digitplanName: string;
  id: string;
  latitude: string;
  longitude: string;
  name: string;
  siloQuantity: number;
  orders: IOrder[];
  silos: ISilo[];
}

export interface IFarmState {
  editSiloError: boolean;
  editSiloLoading: boolean;
  requestSiloListError: boolean;
  farms: IFarm[];
  selectedFarmId: string;
  selectedSiloId: string;
}

export interface IEditSiloFeed {
  type: typeof EDIT_SILO_FEED;
  payload: {
    siloId: string;
    feedId: string;
  };
}

export interface IEditSiloFeedError {
  type: typeof EDIT_SILO_FEED_ERROR;
}

export interface IEditSiloFeedSuccess {
  type: typeof EDIT_SILO_FEED_SUCCESS;
  payload: {
    farms: IFarm[];
  };
}

export interface IEditSiloInfo {
  type: typeof EDIT_SILO_INFO;
  payload: {
    animalId: string | null;
    animalQuantity: number | null;
    feedDensity: number;
    feedId: string;
    feedName: string;
    name: string;
    siloId: string;
  };
}

export interface IEditSiloInfoError {
  type: typeof EDIT_SILO_INFO_ERROR;
}

export interface IEditSiloInfoSuccess {
  type: typeof EDIT_SILO_INFO_SUCCESS;
  payload: {
    farms: IFarm[];
  };
}

export interface IRequestFarms {
  type: typeof REQUEST_FARMS;
}

export interface IRequestFarmsError {
  type: typeof REQUEST_FARMS_ERROR;
}

export interface IRequestSiloListError {
  type: typeof REQUEST_SILO_LIST_ERROR;
}

export interface IRequestFarmsSuccess {
  type: typeof REQUEST_FARMS_SUCCESS;
  payload: {
    farms: IFarm[];
  };
}

export interface IRequestSilo {
  type: typeof REQUEST_SILO;
  payload: {
    siloId: string;
    timePeriod: number;
  };
}
export interface IRequestSiloList {
  type: typeof REQUEST_SILO_LIST;
  payload: {
    farmId: string;
  };
}
export interface IRequestSiloListSuccess {
  type: typeof REQUEST_SILO_LIST_SUCCESS;
  payload: {
    farm: IFarm;
  };
}
export interface IRequestSiloSuccess {
  type: typeof REQUEST_SILO_SUCCESS;
  payload: {
    farms: IFarm[];
  };
}

export interface ISelectFarm {
  type: typeof SELECT_FARM;
  payload: {
    farmId: string;
  };
}

export interface ISelectSilo {
  type: typeof SELECT_SILO;
  payload: {
    siloId: string;
  };
}

export type FarmActionTypes =
  | IEditSiloFeed
  | IEditSiloFeedError
  | IEditSiloFeedSuccess
  | IEditSiloInfo
  | IEditSiloInfoError
  | IEditSiloInfoSuccess
  | IRequestFarms
  | IRequestFarmsError
  | IRequestFarmsSuccess
  | IRequestSiloList
  | IRequestSiloListError
  | IRequestSilo
  | IRequestSiloListSuccess
  | IRequestSiloSuccess
  | ISelectFarm
  | ISelectSilo;
