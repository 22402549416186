import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@cf-design-system/button";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { Radio, RadioGroup } from "@cf-design-system/radio";
import { Text, HighlightText } from "@cf-design-system/typography";
import { setLanguage as setGlobalLanguage } from "../../store/content/actions";
import { getCurrentContent, getCurrentLanguage, getLanguages } from "../../store/content/selectors";
import { ILanguage } from "../../store/content/types";
import PageHeader from "../header/PageHeader";
import "./language.scss";

const Language: React.FC = () => {
  const dispatch = useDispatch();
  const { language: content } = useSelector(getCurrentContent);
  const availableLanguages = useSelector(getLanguages);
  const currentLanguage = useSelector(getCurrentLanguage);
  const [language, setLanguage] = useState(currentLanguage);

  const handleLanguageChange = useCallback(
    (languageCode: string) => setLanguage(languageCode),
    [setLanguage]
  );

  const handleSave = useCallback(() => {
    if (language !== currentLanguage) {
      dispatch(setGlobalLanguage(language));
    }
  }, [currentLanguage, dispatch, language]);

  const handleCancel = useCallback(
    () => setLanguage(currentLanguage),
    [currentLanguage, setLanguage]
  );

  return (
    <section className="language">
      <PageHeader className="language-header" backRoute="back" title={content.common_language} />
      <BackgroundCard className="language-body">
        <HighlightText>{content.language_pickerTitle}</HighlightText>
        <RadioGroup
          className="language-radiogroup"
          name="language"
          selectedValue={language}
          onValueSelect={handleLanguageChange}
        >
          {availableLanguages.map((lang: ILanguage) => (
            <Radio key={lang.code} label={<Text>{lang.name}</Text>} readOnly value={lang.code} />
          ))}
        </RadioGroup>
        <Divider className="actions-divider" />
        <div className="actions">
          <PrimaryButton className="save-button" type="submit" onClick={handleSave}>
            {content.common_save}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            onClick={handleCancel}
            disabled={language === currentLanguage}
          >
            {content.common_cancel}
          </SecondaryButton>
        </div>
      </BackgroundCard>
    </section>
  );
};

export default Language;
