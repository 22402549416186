import React from "react";
import { Text } from "@cf-design-system/typography";
import "./map.scss";

export enum EClusterType {
  danger = "danger",
  default = "",
  warning = "warning"
}

export interface IProps {
  type?: EClusterType;
  label?: string | number;
}

const Cluster: React.FC<IProps> = ({ type = "", label }) => {
  return (
    <div className={`cluster-outer ${type}`}>
      <div className={`cluster-inner ${type}`}>{label && <Text>{label}</Text>}</div>
    </div>
  );
};

export default Cluster;
