import React, { useMemo } from "react";
import { Silo as BaseSilo } from "@cf-design-system/silo";
import { ISilo } from "../../store/farm/types";

interface IProps {
  height?: string;
  shouldAnimate?: boolean;
  silo: ISilo;
  width?: string;
}

const Silo: React.FC<IProps> = ({ height, shouldAnimate, silo, width }) => {
  const { lastMeasure } = silo;

  // as height is not a very reliable value, use weight to calculate fill pct
  const heightPct = useMemo(
    () => (((lastMeasure?.weight ?? 0) / silo.weight) * 100).toFixed(),
    [lastMeasure, silo.weight]
  );

  return (
    <BaseSilo height={height} heightPct={heightPct} shouldAnimate={shouldAnimate} width={width} />
  );
};

export default Silo;
