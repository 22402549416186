import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Icon } from "@cf-design-system/icon";
import { SmallText, H2, DetailText } from "@cf-design-system/typography";
import { getCurrentContent } from "../../store/content/selectors";
import "./pageHeader.scss";

interface IProps {
  backRoute?: string;
  subTitle?: string;
  title?: string | (() => JSX.Element);
  className?: string;
}

const PageHeader: React.FC<IProps & RouteComponentProps> = ({
  backRoute = "",
  history,
  title,
  subTitle,
  className = ""
}) => {
  const { header: content } = useSelector(getCurrentContent);

  const back = useMemo(
    () => (history.location.state as { back: Location })?.back,
    [history.location.state]
  );

  const handleBack = useCallback(
    () =>
      backRoute === "back"
        ? back
          ? history.push(back)
          : history.goBack()
        : history.push(backRoute),
    [backRoute, back, history]
  );

  return (
    <header className={classnames("page-header", { [`${className}`]: !!className })}>
      <div className="page-header-grid">
        {backRoute && (
          <button className="back-button" type="button" onClick={handleBack}>
            <Icon fill="var(--color-black)" name="chevronLeft" />
            <SmallText>{content.header_back}</SmallText>
          </button>
        )}
        {title &&
          (typeof title === "string" ? (
            <H2 className="page-title">{title}</H2>
          ) : (
            <div className="page-title">{title()}</div>
          ))}
      </div>
      {subTitle && <DetailText className="page-subtitle">{subTitle}</DetailText>}
    </header>
  );
};

export default withRouter(PageHeader);
