import React from "react";
import "leaflet.markercluster";
import { ISilo } from "../../store/farm/types";
import { getMissingDaysIndicatorColor } from "./helpers";
import Silo from "../silos/Silo";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "./map.scss";

interface IProps {
  silo: ISilo;
}

const Marker: React.FC<IProps> = ({ silo }) => {
  const { capacityPct, missingDaysNr } = silo;

  return (
    <div className="marker">
      <span className={`marker-label ${getMissingDaysIndicatorColor(missingDaysNr)}`}>
        {capacityPct}%
      </span>
      <Silo silo={silo} width="20" height="48" shouldAnimate={false} />
    </div>
  );
};

export default Marker;
