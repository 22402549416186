import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "@cf-design-system/button";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { DetailText, SubHeading } from "@cf-design-system/typography";
import { getCurrentContent } from "../../store/content/selectors";
import { acceptPrivacyPolicy } from "../../store/user/actions";
import { getUserLoggedIn, getUserPrivacyPolicy } from "../../store/user/selectors";
import PageHeader from "../header/PageHeader";
import "./privacyPolicy.scss";

const PrivacyPolicy: React.FC = () => {
  const dispatch = useDispatch();
  const { privacyPolicy: content } = useSelector(getCurrentContent);
  const acceptedPolicy = useSelector(getUserPrivacyPolicy);
  const loggedIn = useSelector(getUserLoggedIn);

  const handleAgree = useCallback(() => {
    dispatch(acceptPrivacyPolicy());
  }, [dispatch]);

  return (
    <section className="privacy-policy">
      <PageHeader
        className="privacy-policy-header"
        backRoute={loggedIn && !acceptedPolicy ? undefined : "back"}
        title={content.common_termsService}
      />
      <BackgroundCard className="privacy-policy-body">
        <DetailText>{content.privacyPolicy_subTitle}</DetailText>
        <SubHeading className="title">{content.common_termsService}</SubHeading>
        <DetailText dangerouslySetInnerHTML={{ __html: content.privacyPolicy_terms }} />
        <Divider className="actions-divider" />
        {loggedIn && !acceptedPolicy && (
          <PrimaryButton className="agree-button" onClick={handleAgree} type="submit">
            {content.privacyPolicy_agree}
          </PrimaryButton>
        )}
      </BackgroundCard>
    </section>
  );
};

export default PrivacyPolicy;
