import {
  DEFAULT_LANG,
  IApiContentEntry,
  IContent,
  IContentLanguage,
  IContentPage,
  IContentTypeEntries,
  IEntries,
  ILanguage,
  IPageEntries,
  IParsedString,
  ITranslatableString
} from "./types";

const replaceTypeLink = (
  entry: IApiContentEntry,
  contentAssets: IApiContentEntry[],
  contentEntries: IApiContentEntry[]
): IApiContentEntry =>
  Object.keys(entry.fields).reduce(
    (result: IApiContentEntry, field: string) => {
      let fieldEntries = result.fields[field][DEFAULT_LANG] as
        | IApiContentEntry
        | IApiContentEntry[];

      if ((fieldEntries as IApiContentEntry).sys?.type === "Link") {
        const entryFound = [...contentAssets, ...contentEntries].find(
          (apiEntry: IApiContentEntry) =>
            apiEntry.sys.id === (fieldEntries as IApiContentEntry).sys.id
        );

        fieldEntries = entryFound || fieldEntries;
      } else if (Array.isArray(fieldEntries)) {
        fieldEntries = fieldEntries.map((fieldEntry: IApiContentEntry) => {
          let entryFound: IApiContentEntry | undefined;

          if (fieldEntry.sys && fieldEntry.sys.type === "Link") {
            entryFound = [...contentAssets, ...contentEntries].find(
              (apiEntry: IApiContentEntry) => apiEntry.sys.id === fieldEntry.sys.id
            );
          }

          return entryFound
            ? replaceTypeLink(entryFound, contentAssets, contentEntries)
            : fieldEntry;
        });
      }

      return {
        ...result,
        fields: {
          ...result.fields,
          [field]: { ...result.fields[field], [DEFAULT_LANG]: fieldEntries }
        }
      };
    },
    { ...entry }
  );

const translate = <T>(translatable?: Record<string, T>, code = DEFAULT_LANG): T =>
  (translatable ? translatable[code] || translatable[DEFAULT_LANG] || undefined : undefined) as T;

const mapLanguageInfo = (entry: IContentLanguage): ILanguage => ({
  code: translate(entry.fields.languageCode),
  name: translate(entry.fields.languageName)
});

const mapPage = (entries: IContentTypeEntries, code: string): IPageEntries =>
  ((entries.connectedFarmerPage || []) as IContentPage[]).reduce(
    (result: IPageEntries, page: IContentPage) => {
      const pageId = translate(page.fields.pageId);
      const strings = translate(page.fields.pageStrings) || [];

      const parsedStrings = strings.reduce((parsed: IParsedString, string: ITranslatableString) => {
        if (!string.fields) return parsed;

        const key = translate(string.fields.stringKey);
        const contentLong = translate(string.fields.contentLong, code) ?? "";
        const content = translate(string.fields.content, code) ?? "";

        return { ...parsed, [key]: contentLong !== "" ? contentLong : content };
      }, {});

      return { ...result, [pageId]: parsedStrings };
    },
    {}
  );

const mapLanguage = (entries: IContentTypeEntries, code: string): IEntries => ({
  ...mapPage(entries, code)
});

export const mapContentfulEntries = (
  contentAssets: IApiContentEntry[],
  contentEntries: IApiContentEntry[]
): IContent => {
  const contentTypeEntries = contentEntries.reduce(
    (result: IContentTypeEntries, entry: IApiContentEntry) => {
      const { contentType } = entry.sys;
      const newEntry = replaceTypeLink(entry, contentAssets, contentEntries);

      return {
        ...result,
        [contentType]: result[contentType] ? [...result[contentType], newEntry] : [newEntry]
      } as IContentTypeEntries;
    },
    {}
  );

  return ((contentTypeEntries.language || []) as IContentLanguage[]).reduce(
    (result: IContent, entry: IContentLanguage) => {
      const languageInfo = mapLanguageInfo(entry);

      return {
        entries: {
          ...result.entries,
          [languageInfo.code]: mapLanguage(contentTypeEntries, languageInfo.code)
        },
        languages: [...result.languages, languageInfo]
      };
    },
    { entries: {}, languages: [] }
  );
};
