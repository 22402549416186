import React from "react";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DetailText } from "@cf-design-system/typography";
import Routes from "../../routes";
import { getCurrentContent } from "../../store/content/selectors";
import { getUserCountryCode } from "../../store/user/selectors";
import Logo from "../../assets/icons/logo.svg";
import "./footer.scss";

const formLinkMap: { [key: string]: string } = {
  NL: "https://forms.office.com/r/Hq8t85ChCE",
  PT: "https://forms.office.com/r/f2vxAUzyx7"
};

const Footer: React.FC<RouteComponentProps> = ({ history }) => {
  const { footer: content } = useSelector(getCurrentContent);
  const countryCode = useSelector(getUserCountryCode);

  return (
    <footer className="footer">
      <div className="footer-icon">
        <img alt="logo" className="logo" src={Logo} />
      </div>
      <div className="footer-links">
        <div className="footer-link">
          <DetailText>
            <a href={formLinkMap[countryCode]} rel="noopener noreferrer" target="_blank">
              {content.footer_reportProblem}
            </a>
          </DetailText>
        </div>
        <div className="footer-link">
          <DetailText>
            <Link to={{ pathname: Routes.privacyPolicy, state: { back: history.location } }}>
              {content.common_termsService}
            </Link>
          </DetailText>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
