import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "@cf-design-system/button";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { Icon } from "@cf-design-system/icon";
import { ErrorTextInput, TextInput } from "@cf-design-system/textinput";
import { getCurrentContent } from "../../store/content/selectors";
import { editPassword } from "../../store/user/actions";
import {
  getEditPasswordError,
  getEditPasswordLoading,
  getUsername
} from "../../store/user/selectors";
import PageHeader from "../header/PageHeader";
import "./account.scss";

const Account: React.FC = () => {
  const dispatch = useDispatch();
  const { account: content } = useSelector(getCurrentContent);
  const username = useSelector(getUsername);
  const editLoading = useSelector(getEditPasswordLoading);
  const editError = useSelector(getEditPasswordError);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [confirmationPasswordError, setConfirmationPasswordError] = useState(false);
  const [saving, setSaving] = useState(false);

  const enableSave = useMemo(
    () => showEditPassword && !saving && password && newPassword && confirmationPassword,
    [confirmationPassword, newPassword, password, saving, showEditPassword]
  );

  const handleCancel = useCallback(() => {
    setConfirmationPassword("");
    setConfirmationPasswordError(false);
    setNewPassword("");
    setPassword("");
    setShowEditPassword(false);
  }, [
    setConfirmationPassword,
    setConfirmationPasswordError,
    setNewPassword,
    setPassword,
    setShowEditPassword
  ]);

  useEffect(() => {
    if (showEditPassword && saving && !editLoading) {
      setSaving(false);

      if (!editError) {
        handleCancel();
      }
    }
  }, [
    editError,
    editLoading,
    handleCancel,
    saving,
    setSaving,
    setShowEditPassword,
    showEditPassword
  ]);

  const handleEditPassword = useCallback(() => setShowEditPassword(true), [setShowEditPassword]);

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    [setPassword]
  );

  const handleNewPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (confirmationPasswordError) {
        setConfirmationPasswordError(false);
      }

      setNewPassword(e.target.value);
    },
    [confirmationPasswordError, setConfirmationPasswordError, setNewPassword]
  );

  const handleConfirmationPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (confirmationPasswordError) {
        setConfirmationPasswordError(false);
      }

      setConfirmationPassword(e.target.value);
    },
    [confirmationPasswordError, setConfirmationPassword, setConfirmationPasswordError]
  );

  const handleSave = useCallback(() => {
    if (enableSave) {
      if (newPassword !== confirmationPassword) {
        setConfirmationPasswordError(true);
      } else {
        dispatch(editPassword(password, newPassword));
        setSaving(true);
      }
    }
  }, [
    confirmationPassword,
    dispatch,
    enableSave,
    newPassword,
    password,
    setConfirmationPasswordError,
    setSaving
  ]);

  return (
    <section className="account">
      <PageHeader
        className="account-header"
        backRoute="back"
        title={content.common_loginSecurity}
      />
      <BackgroundCard className="account-body">
        <div className="change-password">
          <TextInput
            className="change-password-input"
            disabled
            label={content.common_email}
            placeholder={content.common_email}
            type="email"
            value={username}
          />
          {!showEditPassword ? (
            <TertiaryButton
              className="change-password-button"
              onClick={handleEditPassword}
              renderRightIcon={() => <Icon name="key" />}
              type="button"
            >
              {content.common_changePassword}
            </TertiaryButton>
          ) : (
            <>
              <TextInput
                className="change-password-input"
                label={content.account_currentPassword}
                onChange={handlePasswordChange}
                placeholder={content.account_currentPassword}
                type="password"
                value={password}
              />
              <ErrorTextInput
                className="change-password-input-error"
                error={confirmationPasswordError}
                label={content.common_newPassword}
                onChange={handleNewPasswordChange}
                placeholder={content.common_newPassword}
                type="password"
                value={newPassword}
              />
              <ErrorTextInput
                className="change-password-input-error"
                error={confirmationPasswordError && content.common_matchPasswords}
                label={content.common_confirmPassword}
                onChange={handleConfirmationPasswordChange}
                placeholder={content.common_confirmPassword}
                type="password"
                value={confirmationPassword}
              />
            </>
          )}
        </div>
        <Divider className="actions-divider" />
        <div className="actions">
          <PrimaryButton className="save-button" onClick={handleSave} type="submit">
            {content.common_save}
          </PrimaryButton>
          <SecondaryButton disabled={!showEditPassword} onClick={handleCancel} type="button">
            {content.common_cancel}
          </SecondaryButton>
        </div>
      </BackgroundCard>
    </section>
  );
};

export default Account;
