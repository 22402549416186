export const EDIT_ALARMS = "alarm/EDIT_ALARMS";
export const EDIT_ALARMS_ERROR = "alarm/EDIT_ALARMS_ERROR";
export const EDIT_ALARMS_SUCCESS = "alarm/EDIT_ALARMS_SUCCESS";
export const REQUEST_ALARMS = "alarm/REQUEST_ALARMS";
export const REQUEST_ALARMS_ERROR = "alarm/REQUEST_ALARMS_ERROR";
export const REQUEST_ALARMS_SUCCESS = "alarm/REQUEST_ALARMS_SUCCESS";

export enum AlarmContactType {
  EMAIL = "EMAIL",
  SMS = "SMS"
}

export enum AlarmType {
  DAYS_LEFT = "DAYS_LEFT",
  FEED_LEVEL = "FEED_LEVEL"
}

export enum ApiAlarmType {
  DAYS = "DAYS",
  LEVEL = "LEVEL"
}

export interface IApiAlarm {
  emails: string[];
  isEmail: boolean;
  isSms: boolean;
  phoneNumbers: { countryCode: string; number: string }[];
  siloId: string;
  type: ApiAlarmType;
  value: number;
}

export interface IApiAlarms {
  [siloId: string]: IApiAlarm[];
}

export interface IAlarm {
  alarmType: AlarmContactType;
  contact: string;
  countryCode?: string;
}

export interface IAlarmInfo {
  daysLeft: string;
  daysLeftAlarms: IAlarm[];
  feedLvl: string;
  feedLvlAlarms: IAlarm[];
}

export interface IAlarms {
  [siloId: string]: IAlarmInfo;
}

export interface IAlarmState {
  alarms: IAlarms;
  editAlarmError: boolean;
  editAlarmLoading: boolean;
}

export interface IEditAlarms {
  type: typeof EDIT_ALARMS;
  payload: {
    alarms: IAlarmInfo;
    siloId: string;
  };
}

export interface IEditAlarmsError {
  type: typeof EDIT_ALARMS_ERROR;
}

export interface IEditAlarmsSuccess {
  type: typeof EDIT_ALARMS_SUCCESS;
  payload: {
    alarms: IAlarms;
  };
}

export interface IRequestAlarms {
  type: typeof REQUEST_ALARMS;
}

export interface IRequestAlarmsError {
  type: typeof REQUEST_ALARMS_ERROR;
}

export interface IRequestAlarmsSuccess {
  type: typeof REQUEST_ALARMS_SUCCESS;
  payload: {
    alarms: IAlarms;
  };
}

export type AlarmActionTypes =
  | IEditAlarms
  | IEditAlarmsError
  | IEditAlarmsSuccess
  | IRequestAlarms
  | IRequestAlarmsError
  | IRequestAlarmsSuccess;
