import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import Routes from "../../routes";
import { getUserLoggedIn, getUserPrivacyPolicy } from "../../store/user/selectors";
import Login from "../login/Login";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";

const PrivateRoutes: React.FC<RouteComponentProps> = ({ children, location }) => {
  const loggedIn = useSelector(getUserLoggedIn);
  const acceptedPrivacyPolicy = useSelector(getUserPrivacyPolicy);
  const [hasLoggedIn, setHasLoggedIn] = useState(loggedIn);

  useEffect(() => {
    const scrollingElement = document.getElementById("root");

    scrollingElement?.scrollTo(0, 0);
  }, [acceptedPrivacyPolicy]);

  useEffect(() => {
    if (loggedIn) {
      setHasLoggedIn(true);
    }
  }, [loggedIn, setHasLoggedIn]);

  const from = useMemo(() => (location.state as { from: Location })?.from, [location.state]);

  return loggedIn ? (
    acceptedPrivacyPolicy ? (
      <Switch>
        {from && <Redirect to={from} />}
        {children}
      </Switch>
    ) : (
      <Switch>
        <Route exact path={Routes.home} component={PrivacyPolicy} />
        <Redirect to={{ pathname: Routes.home, state: from ? { from } : { from: location } }} />
      </Switch>
    )
  ) : (
    <Switch>
      <Route path={Routes.recoverPassword} component={Login} />
      <Route exact path={Routes.home} component={Login} />
      <Redirect
        to={{ pathname: Routes.home, state: hasLoggedIn ? undefined : { from: location } }}
      />
    </Switch>
  );
};

export default withRouter(PrivateRoutes);
