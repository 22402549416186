import { Persistor, purgeStoredState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, ForkEffect, put, select, takeLatest } from "redux-saga/effects";
import APIService from "../services/api";
import NotificationsService from "../services/notifications";
import { getCurrentContent } from "../store/content/selectors";
import { IMappedEntries } from "../store/content/types";
import {
  acceptPrivacyPolicySuccess,
  editPasswordError,
  editPasswordSuccess,
  loginError,
  loginSuccess,
  logoutSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  storeToken
} from "../store/user/actions";
import {
  ACCEPT_PRIVACY_POLICY,
  EDIT_PASSWORD,
  IEditPassword,
  ILogin,
  IRecoverPassword,
  IResetPassword,
  IUser,
  IValidateResetToken,
  LOGIN,
  LOGOUT,
  RECOVER_PASSWORD,
  REQUEST_USER,
  RESET_PASSWORD,
  VALIDATE_RESET_TOKEN
} from "../store/user/types";

function* acceptPrivacyPolicy() {
  const { privacyPolicy: content } = (yield select(getCurrentContent)) as IMappedEntries;

  try {
    yield call(APIService.acceptPrivacyPolicy);

    yield put(acceptPrivacyPolicySuccess());
  } catch (error: unknown) {
    NotificationsService.addNotification({
      isError: true,
      text: content.privacyPolicy_notificationError
    });
  }
}

function* editPassword(action: IEditPassword) {
  const { account: content } = (yield select(getCurrentContent)) as IMappedEntries;

  try {
    yield call(APIService.editPassword, action.payload.currentPassword, action.payload.newPassword);

    yield put(editPasswordSuccess());

    NotificationsService.addNotification({
      text: content.account_passwordNotificationSuccess
    });
  } catch (error: unknown) {
    yield put(editPasswordError());

    NotificationsService.addNotification({
      isError: true,
      text: content.account_passwordNotificationError
    });
  }
}

function* login(action: ILogin) {
  try {
    const token = (yield call(
      APIService.login,
      action.payload.username,
      action.payload.password
    )) as string;

    yield put(storeToken(token));

    const user = (yield call(APIService.getUser)) as IUser;

    yield put(loginSuccess(user));
  } catch (error: unknown) {
    yield put(loginError());
  }
}

function* logout(persistor: Persistor) {
  yield call(persistor.pause);
  yield call(persistor.flush);
  yield call(purgeStoredState, { key: "farm", storage });
  yield call(purgeStoredState, { key: "user", storage });
  yield put(logoutSuccess());
  yield call(persistor.persist);
}

function* recoverPassword(action: IRecoverPassword) {
  const { login: content } = (yield select(getCurrentContent)) as IMappedEntries;

  try {
    yield call(APIService.recoverPassword, action.payload.username);

    NotificationsService.addNotification({
      text: content.login_recoverNotificationSuccess
    });
  } catch (error: unknown) {
    NotificationsService.addNotification({
      isError: true,
      text: content.login_recoverNotificationError
    });
  }
}

function* requestUser() {
  try {
    const user = (yield call(APIService.getUser)) as IUser;

    yield put(loginSuccess(user));
  } catch (error: unknown) {
    // Do nothing.
  }
}

function* resetPassword(action: IResetPassword) {
  const { login: content } = (yield select(getCurrentContent)) as IMappedEntries;

  try {
    yield call(APIService.resetPassword, action.payload.token, action.payload.password);

    yield put(resetPasswordSuccess());

    NotificationsService.addNotification({
      text: content.login_resetNotificationSuccess
    });
  } catch (error: unknown) {
    yield put(resetPasswordError());

    NotificationsService.addNotification({
      isError: true,
      text: content.login_resetNotificationError
    });
  }
}

function* validateResetToken(action: IValidateResetToken) {
  const { login: content } = (yield select(getCurrentContent)) as IMappedEntries;

  try {
    yield call(APIService.validateResetToken, action.payload.token);
  } catch (error: unknown) {
    NotificationsService.addNotification({
      isError: true,
      text: content.login_validateNotificationError
    });
  }
}

function* userWatcher(persistor: Persistor): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(ACCEPT_PRIVACY_POLICY, acceptPrivacyPolicy);
  yield takeLatest(EDIT_PASSWORD, editPassword);
  yield takeLatest(LOGIN, login);
  yield takeLatest(LOGOUT, logout, persistor);
  yield takeLatest(RECOVER_PASSWORD, recoverPassword);
  yield takeLatest(REQUEST_USER, requestUser);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(VALIDATE_RESET_TOKEN, validateResetToken);
}

export default userWatcher;
