import { IMeasure, ISiloDayConsumptionData } from "../../store/farm/types";

const FILLED_THRESHOLD_PERCENTAGE = 20;

// If there is a gap in the measures dates, fill missing days for consistency.
export const fillMissingDays = (measures: ISiloDayConsumptionData[]): ISiloDayConsumptionData[] =>
  measures.reduce((acc: ISiloDayConsumptionData[], measure: ISiloDayConsumptionData, i: number) => {
    if (measures.length === i + 1) {
      return [...acc, measure];
    }

    const fillerMeasures: ISiloDayConsumptionData[] = [];
    const dayAfter = new Date(measure.date);

    dayAfter.setDate(dayAfter.getDate() + 1);

    while (dayAfter.getDate() !== measures[i + 1].date.getDate()) {
      fillerMeasures.push({
        dateString: dayAfter.toLocaleDateString("default", {
          month: "short",
          day: "numeric"
        }),
        date: new Date(dayAfter),
        timeString: new Date(dayAfter).toLocaleTimeString("default", {
          hour: "2-digit",
          minute: "2-digit"
        }),
        weight: measures[i].weight
      });
      dayAfter.setDate(dayAfter.getDate() + 1);
    }

    return [...acc, measure, ...fillerMeasures];
  }, []);

// Go through all measures and filter them so that there is only one data point per day.
export const getChartData = (measures: IMeasure[]): ISiloDayConsumptionData[] =>
  [...measures]
    .sort((a: IMeasure, b: IMeasure) => (a.readingTime > b.readingTime ? 1 : -1))
    .reduce((filtered: ISiloDayConsumptionData[], measure: IMeasure, index: number) => {
      // Only 1 measurement.
      if (measures.length === 1) {
        return [
          ...filtered,
          {
            dateString: new Date(measure.readingTime).toLocaleDateString("default", {
              month: "short",
              day: "numeric"
            }),
            date: new Date(measure.readingTime),
            timeString: new Date(measure.readingTime).toLocaleTimeString("default", {
              hour: "2-digit",
              minute: "2-digit"
            }),
            weight: measure.weight
          }
        ];
      }

      const measureDate = new Date(measure.readingTime).getDate();

      // Compare current measure date with next measure date,
      // if measure date is last array entry, compare with prev measure date.
      const compMeasureDate =
        measures.length === index + 1
          ? filtered[filtered.length - 1]?.date
          : new Date(measures[index + 1].readingTime).getDate();

      return measureDate !== compMeasureDate
        ? [
            ...filtered,
            {
              dateString: new Date(measure.readingTime).toLocaleDateString("default", {
                month: "short",
                day: "numeric"
              }),
              timeString: new Date(measure.readingTime).toLocaleTimeString("default", {
                hour: "2-digit",
                minute: "2-digit"
              }),
              date: new Date(measure.readingTime),
              weight: measure.weight
            }
          ]
        : filtered;
    }, []);

export const getFillData = (
  measures: IMeasure[],
  siloWeight: number
): ISiloDayConsumptionData | undefined => {
  let maxMeasure;
  let lastMeasure;

  for (let i = measures.length - 1; i > 0; i--) {
    if (Math.round(measures[i].weight / 100) / 10 > Math.round(measures[i - 1].weight / 100) / 10) {
      if (!maxMeasure) {
        maxMeasure = measures[i];
      }

      lastMeasure = measures[i - 1];

      if (
        Math.round(maxMeasure.weight / 100) / 10 >
        Math.round(lastMeasure.weight / 100) / 10 +
          Math.round((siloWeight * (FILLED_THRESHOLD_PERCENTAGE / 100)) / 100 / 10)
      ) {
        return getChartData([maxMeasure])[0];
      }
    } else {
      maxMeasure = undefined;
      lastMeasure = undefined;
    }
  }

  return undefined;
};
