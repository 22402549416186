import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Tab, TabGroup, ITabGroupRef } from "@cf-design-system/tab";
import Routes from "../../routes";
import { getCurrentContent } from "../../store/content/selectors";
import { requestSiloList, selectSilo } from "../../store/farm/actions";
import {
  getSiloListError,
  getSelectedFarm,
  getSelectedFarmId,
  getSelectedSiloId
} from "../../store/farm/selectors";
import { ISilo } from "../../store/farm/types";
import Legend from "../map/Legend";
import OrderHistory from "../orderHistory/OrderHistory";
import PageHeader from "../header/PageHeader";
import SiloListItem from "./SiloListItem";
import "./siloList.scss";

const SiloList: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { siloList: content } = useSelector(getCurrentContent);
  const selectedFarmId = useSelector(getSelectedFarmId);
  const selectedSiloId = useSelector(getSelectedSiloId);
  const farm = useSelector(getSelectedFarm);
  const tabGroupRef = useRef<ITabGroupRef>(null);
  const requestSiloListError = useSelector(getSiloListError);

  useEffect(() => {
    if (!selectedFarmId) {
      history.replace(Routes.home);
    }
  }, [history, selectedFarmId]);

  useEffect(() => {
    if (selectedFarmId && !farm?.silos) {
      dispatch(requestSiloList(selectedFarmId));
    }
  }, [dispatch, farm, selectedFarmId]);

  // Reset selected silo.
  useEffect(() => {
    if (selectedSiloId) {
      dispatch(selectSilo(""));
    }
  }, [dispatch, selectedSiloId]);

  return (
    <>
      <PageHeader backRoute={Routes.home} className="silos-header" title={farm?.name} />
      {farm?.silos ? (
        <section className="silos">
          <TabGroup className="silos-tab-group" ref={tabGroupRef}>
            <Tab label={content.siloList_silos}>
              <Legend content={content} />
              <div className="silos-list">
                {farm?.silos.map((silo: ISilo) => (
                  <SiloListItem key={silo.id} silo={silo} />
                ))}
              </div>
            </Tab>
            <Tab label={content.siloList_orders}>
              <OrderHistory />
            </Tab>
          </TabGroup>
        </section>
      ) : (
        <div className="silo-message">
          {requestSiloListError ? (
            <span>Error getting farm details, please try again later.</span>
          ) : (
            <span>Loading...</span>
          )}
        </div>
      )}
    </>
  );
};

export default SiloList;
