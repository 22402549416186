export const ACCEPT_PRIVACY_POLICY = "user/ACCEPT_PRIVACY_POLICY";
export const ACCEPT_PRIVACY_POLICY_ERROR = "user/ACCEPT_PRIVACY_POLICY_ERROR";
export const ACCEPT_PRIVACY_POLICY_SUCCESS = "user/ACCEPT_PRIVACY_POLICY_SUCCESS";
export const EDIT_PASSWORD = "user/EDIT_PASSWORD";
export const EDIT_PASSWORD_ERROR = "user/EDIT_PASSWORD_ERROR";
export const EDIT_PASSWORD_SUCCESS = "user/EDIT_PASSWORD_SUCCESS";
export const LOGIN = "user/LOGIN";
export const LOGIN_ERROR = "user/LOGIN_ERROR";
export const LOGIN_SUCCESS = "user/LOGIN_SUCCESS";
export const LOGOUT = "user/LOGOUT";
export const LOGOUT_SUCCESS = "user/LOGOUT_SUCCESS";
export const RECOVER_PASSWORD = "user/RECOVER_PASSWORD";
export const REQUEST_USER = "user/REQUEST_USER";
export const RESET_PASSWORD = "user/RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "user/RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS = "user/RESET_PASSWORD_SUCCESS";
export const STORE_TOKEN = "user/STORE_TOKEN";
export const VALIDATE_RESET_TOKEN = "user/VALIDATE_RESET_TOKEN";

export interface IUser {
  countryCode: string;
  privacyPolicy: boolean;
  username: string;
}

export interface IUserState {
  countryCode: string;
  editPasswordError: boolean;
  editPasswordLoading: boolean;
  hasLoginError: boolean;
  hasAcceptedPrivacyPolicy: boolean;
  resetPasswordError: boolean;
  resetPasswordLoading: boolean;
  token: string;
  username: string;
}

export interface IAcceptPrivacyPolicy {
  type: typeof ACCEPT_PRIVACY_POLICY;
}

export interface IAcceptPrivacyPolicyError {
  type: typeof ACCEPT_PRIVACY_POLICY_ERROR;
}

export interface IAcceptPrivacyPolicySuccess {
  type: typeof ACCEPT_PRIVACY_POLICY_SUCCESS;
}

export interface IEditPassword {
  type: typeof EDIT_PASSWORD;
  payload: {
    currentPassword: string;
    newPassword: string;
  };
}

export interface IEditPasswordError {
  type: typeof EDIT_PASSWORD_ERROR;
}

export interface IEditPasswordSuccess {
  type: typeof EDIT_PASSWORD_SUCCESS;
}

export interface ILogin {
  type: typeof LOGIN;
  payload: {
    password: string;
    username: string;
  };
}

export interface ILoginError {
  type: typeof LOGIN_ERROR;
}

export interface ILoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: {
    user: IUser;
  };
}

export interface ILogout {
  type: typeof LOGOUT;
}

export interface ILogoutSuccess {
  type: typeof LOGOUT_SUCCESS;
}

export interface IRecoverPassword {
  type: typeof RECOVER_PASSWORD;
  payload: {
    username: string;
  };
}

export interface IRequestUser {
  type: typeof REQUEST_USER;
}

export interface IResetPassword {
  type: typeof RESET_PASSWORD;
  payload: {
    password: string;
    token: string;
  };
}

export interface IResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR;
}

export interface IResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export interface IStoreToken {
  type: typeof STORE_TOKEN;
  payload: {
    token: string;
  };
}

export interface IValidateResetToken {
  type: typeof VALIDATE_RESET_TOKEN;
  payload: {
    token: string;
  };
}

export type UserActionTypes =
  | IAcceptPrivacyPolicy
  | IAcceptPrivacyPolicyError
  | IAcceptPrivacyPolicySuccess
  | IEditPassword
  | IEditPasswordError
  | IEditPasswordSuccess
  | ILogin
  | ILoginError
  | ILoginSuccess
  | ILogout
  | ILogoutSuccess
  | IRecoverPassword
  | IResetPassword
  | IResetPasswordError
  | IResetPasswordSuccess
  | IStoreToken
  | IValidateResetToken;
