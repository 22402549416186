import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import {
  INotification,
  INotificationStackRef,
  NotificationProvider,
  NotificationStack,
  useNotificationContext
} from "@cf-design-system/notification";
import { defaultTheme } from "@cf-design-system/tokens";
import Routes from "./routes";
import NotificationsService from "./services/notifications";
import { requestAlarms } from "./store/alarm/actions";
import { requestAnimals } from "./store/animal/actions";
import { requestFarms, requestSiloList } from "./store/farm/actions";
import { getSelectedFarm, getSelectedFarmId } from "./store/farm/selectors";
import { requestFeeds } from "./store/feed/actions";
import { requestUser } from "./store/user/actions";
import { store, persistor } from "./store/setup";
import { getUserLoggedIn } from "./store/user/selectors";
import Alarms from "./components/alarms/Alarms";
import ScrollToTop from "./components/common/ScrollToTop";
import FarmList from "./components/farms/FarmList";
import Footer from "./components/footer/Footer";
import PrivateRoutes from "./components/privateRoutes/PrivateRoutes";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import Account from "./components/settings/Account";
import Language from "./components/settings/Language";
import SiloDetails from "./components/silos/SiloDetails";
import SiloInfo from "./components/silos/SiloInfo";
import SiloList from "./components/silos/SiloList";
import Header from "./components/header/Header";

const InnerAppWithRouter: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getUserLoggedIn);
  const selectedFarmId = useSelector(getSelectedFarmId);
  const farm = useSelector(getSelectedFarm);

  useEffect(() => {
    if (loggedIn) {
      dispatch(requestAlarms());
      dispatch(requestAnimals());
      dispatch(requestFarms());
      dispatch(requestFeeds());
      dispatch(requestUser());
    }
  }, [dispatch, loggedIn]);

  // fetch farm details in pages that need it
  useEffect(() => {
    if (
      loggedIn &&
      !!selectedFarmId &&
      !!farm &&
      !farm.silos &&
      (history.location.pathname === Routes.silo ||
        history.location.pathname === Routes.siloInfo ||
        history.location.pathname === Routes.alarms)
    ) {
      dispatch(requestSiloList(selectedFarmId));
    }
  }, [dispatch, farm, history.location.pathname, loggedIn, selectedFarmId]);

  return (
    <Switch>
      <Route path={Routes.privacyPolicy} component={PrivacyPolicy} />
      <PrivateRoutes>
        <Route path={Routes.account} component={Account} />
        <Route path={Routes.alarms} component={Alarms} />
        <Route path={Routes.language} component={Language} />
        <Route path={Routes.silo} component={SiloDetails} />
        <Route path={Routes.silos} component={SiloList} />
        <Route path={Routes.siloInfo} component={SiloInfo} />
        <Route exact path={Routes.home} component={FarmList} />
        <Redirect to={Routes.home} />
      </PrivateRoutes>
    </Switch>
  );
};

const InnerApp = withRouter(InnerAppWithRouter);

const App: React.FC = () => {
  const notificationStackRef = useRef<INotificationStackRef>(null);
  const notificationContext = useNotificationContext<INotificationStackRef, INotification>(
    notificationStackRef
  );

  NotificationsService.setNotificationsContext(notificationContext);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <ScrollToTop />
            <ThemeProvider theme={defaultTheme}>
              <NotificationProvider value={notificationContext}>
                <Header />
                <div id="app">
                  <InnerApp />
                  <Footer />
                </div>
              </NotificationProvider>
              <NotificationStack ref={notificationStackRef} />
            </ThemeProvider>
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
