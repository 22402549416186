import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Icon } from "@cf-design-system/icon";
import { Link } from "@cf-design-system/link";
import { Menu, MenuItem, MenuSection } from "@cf-design-system/menu";
import { SmallHeading } from "@cf-design-system/typography";
import Routes from "../../routes";
import { getCurrentContent } from "../../store/content/selectors";
import { logout } from "../../store/user/actions";
import { getUserLoggedIn, getUsername } from "../../store/user/selectors";
import Logo from "../../assets/icons/LogoHeader.svg";
import "./header.scss";

const Header: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { header: content } = useSelector(getCurrentContent);
  const loggedIn = useSelector(getUserLoggedIn);
  const username = useSelector(getUsername);

  const handleLogoClick = useCallback(
    () => history.push(Routes.home, { resetScroll: true }),
    [history]
  );

  const handleAccountClick = useCallback(
    () => history.push(Routes.account, { back: history.location }),
    [history]
  );

  const handleLanguageClick = useCallback(
    () => history.push(Routes.language, { back: history.location }),
    [history]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const renderMenuHeader = useCallback(
    () => (
      <div className="user-menu-wrapper">
        <Icon frameClassName="user-menu-icon" hasFrame name="menu" />
      </div>
    ),
    []
  );

  return (
    <header className="header">
      <div className="header-grid">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img alt="logo" className="logo" src={Logo} onClick={handleLogoClick} />
        <div className="user-menu">
          {loggedIn && (
            <>
              <Link href="https://www.mijndeheus.nl" className="header-store-link">
                {content.common_storeLink}
              </Link>
              <Menu
                centered
                className="user-menu-popover"
                direction="bottom-right"
                renderHeader={renderMenuHeader}
              >
                <MenuSection
                  orientation="vertical"
                  title={() => (
                    <SmallHeading className="user-menu-username">
                      {`${username}`.toLocaleUpperCase()}
                    </SmallHeading>
                  )}
                >
                  <MenuItem
                    icon="key"
                    label={() => <SmallHeading>{content.common_loginSecurity}</SmallHeading>}
                    onClick={handleAccountClick}
                  />
                  <MenuItem
                    icon="alignLeft"
                    label={() => <SmallHeading>{content.common_language}</SmallHeading>}
                    onClick={handleLanguageClick}
                  />
                </MenuSection>
                <MenuSection>
                  <MenuItem
                    icon="logout"
                    label={() => <SmallHeading>{content.header_logout}</SmallHeading>}
                    onClick={handleLogout}
                  />
                </MenuSection>
              </Menu>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
