import { INotification, INotificationContext } from "@cf-design-system/notification";

export interface INotificationsService {
  addNotification: (notification: INotification) => void;
  removeNotification: (notificationId: string) => void;
  setNotificationsContext: (notificationsContext: INotificationContext<INotification>) => void;
}

class NotificationsService implements INotificationsService {
  private notificationsContext: INotificationContext<INotification>;

  constructor() {
    this.notificationsContext = {
      addNotification: () => undefined,
      removeNotification: () => undefined
    };
  }

  addNotification = (notification: INotification): string | undefined =>
    this.notificationsContext.addNotification(notification);

  removeNotification = (notificationId: string): void =>
    this.notificationsContext.removeNotification(notificationId);

  setNotificationsContext = (notificationsContext: INotificationContext<INotification>): void => {
    this.notificationsContext = notificationsContext;
  };
}

export default new NotificationsService();
