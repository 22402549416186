import { IFarm, ISilo } from "./types";

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const mapEditedSiloFarms = (editedSilo: ISilo, farms: IFarm[]): IFarm[] =>
  farms.map((farm: IFarm): IFarm => {
    const silos = farm.silos.map((silo: ISilo) =>
      silo.id === editedSilo.id
        ? {
            ...silo,
            ...editedSilo,
            measures: silo.measures,
            predictedMeasures: silo.predictedMeasures
          }
        : silo
    );

    return { ...farm, silos };
  });

const mapSiloMissingDays = (silo: ISilo): ISilo => {
  const { lastMeasure, lastPredictedMeasure } = silo;
  const capacityPct = Math.round(((lastMeasure?.volume ?? 0) / silo.capacity) * 100);
  const missingDaysNr =
    lastMeasure &&
    lastPredictedMeasure &&
    Math.round(
      (new Date(lastPredictedMeasure.readingTime).getTime() -
        new Date(lastMeasure.readingTime).getTime()) /
        MS_PER_DAY
    );

  return { ...silo, capacityPct, missingDaysNr: missingDaysNr >= 0 ? missingDaysNr : null };
};

export const mapSilosMissingDays = (farm: IFarm): IFarm => {
  const silos = farm.silos.map((silo: ISilo): ISilo => mapSiloMissingDays(silo));

  return { ...farm, silos };
};

export const mapSiloMeasures = (siloWithMeasures: ISilo, farms: IFarm[]): IFarm[] =>
  farms.map((farm: IFarm): IFarm => {
    const silos = farm.silos?.map((silo: ISilo) =>
      silo.id === siloWithMeasures.id ? mapSiloMissingDays(siloWithMeasures) : silo
    );

    return { ...farm, silos };
  });
