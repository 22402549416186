import {
  EDIT_SILO_FEED,
  EDIT_SILO_FEED_ERROR,
  EDIT_SILO_FEED_SUCCESS,
  EDIT_SILO_INFO,
  EDIT_SILO_INFO_ERROR,
  EDIT_SILO_INFO_SUCCESS,
  IEditSiloFeed,
  IEditSiloFeedError,
  IEditSiloFeedSuccess,
  IEditSiloInfo,
  IEditSiloInfoError,
  IEditSiloInfoSuccess,
  IFarm,
  IRequestFarms,
  IRequestFarmsError,
  IRequestSiloListError,
  IRequestFarmsSuccess,
  IRequestSilo,
  IRequestSiloList,
  IRequestSiloListSuccess,
  IRequestSiloSuccess,
  ISelectFarm,
  ISelectSilo,
  REQUEST_FARMS,
  REQUEST_FARMS_ERROR,
  REQUEST_FARMS_SUCCESS,
  REQUEST_SILO_LIST_ERROR,
  REQUEST_SILO,
  REQUEST_SILO_LIST,
  REQUEST_SILO_LIST_SUCCESS,
  REQUEST_SILO_SUCCESS,
  SELECT_FARM,
  SELECT_SILO
} from "./types";

export const editSiloFeed = (siloId: string, feedId: string): IEditSiloFeed => ({
  type: EDIT_SILO_FEED,
  payload: {
    feedId,
    siloId
  }
});

export const editSiloFeedError = (): IEditSiloFeedError => ({ type: EDIT_SILO_FEED_ERROR });

export const editSiloFeedSuccess = (farms: IFarm[]): IEditSiloFeedSuccess => ({
  type: EDIT_SILO_FEED_SUCCESS,
  payload: {
    farms
  }
});

export const editSiloInfo = (
  siloId: string,
  feedId: string,
  feedDensity: number,
  name: string,
  feedName: string,
  animalId: string | null,
  animalQuantity: number | null
): IEditSiloInfo => ({
  type: EDIT_SILO_INFO,
  payload: {
    animalId,
    animalQuantity,
    feedDensity,
    feedId,
    feedName,
    name,
    siloId
  }
});

export const editSiloInfoError = (): IEditSiloInfoError => ({ type: EDIT_SILO_INFO_ERROR });

export const editSiloInfoSuccess = (farms: IFarm[]): IEditSiloInfoSuccess => ({
  type: EDIT_SILO_INFO_SUCCESS,
  payload: {
    farms
  }
});

export const requestFarms = (): IRequestFarms => ({ type: REQUEST_FARMS });

export const requestFarmsError = (): IRequestFarmsError => ({ type: REQUEST_FARMS_ERROR });

export const requestSiloListError = (): IRequestSiloListError => ({
  type: REQUEST_SILO_LIST_ERROR
});

export const requestFarmsSuccess = (farms: IFarm[]): IRequestFarmsSuccess => ({
  type: REQUEST_FARMS_SUCCESS,
  payload: {
    farms
  }
});

export const requestSilo = (siloId: string, timePeriod: number): IRequestSilo => ({
  type: REQUEST_SILO,
  payload: {
    siloId,
    timePeriod
  }
});
export const requestSiloList = (farmId: string): IRequestSiloList => ({
  type: REQUEST_SILO_LIST,
  payload: {
    farmId
  }
});
export const requestSiloListSuccess = (farm: IFarm): IRequestSiloListSuccess => ({
  type: REQUEST_SILO_LIST_SUCCESS,
  payload: {
    farm
  }
});
export const requestSiloSuccess = (farms: IFarm[]): IRequestSiloSuccess => ({
  type: REQUEST_SILO_SUCCESS,
  payload: {
    farms
  }
});

export const selectFarm = (farmId: string): ISelectFarm => ({
  type: SELECT_FARM,
  payload: {
    farmId
  }
});

export const selectSilo = (siloId: string): ISelectSilo => ({
  type: SELECT_SILO,
  payload: {
    siloId
  }
});
