import { Action, combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import alarmReducer from "./alarm/reducer";
import animalReducer from "./animal/reducer";
import contentReducer from "./content/reducer";
import farmReducer from "./farm/reducer";
import feedReducer from "./feed/reducer";
import userReducer from "./user/reducer";
import { LOGOUT_SUCCESS } from "./user/types";

const appReducer = combineReducers({
  alarm: alarmReducer,
  animal: animalReducer,
  content: persistReducer({ key: "content", storage }, contentReducer),
  farm: persistReducer(
    { key: "farm", storage, whitelist: ["selectedFarmId", "selectedSiloId"] },
    farmReducer
  ),
  feed: feedReducer,
  user: persistReducer(
    {
      key: "user",
      storage,
      whitelist: ["countryCode", "hasAcceptedPrivacyPolicy", "token", "username"]
    },
    userReducer
  )
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState | undefined, action: Action): AppState => {
  if (action.type !== LOGOUT_SUCCESS) {
    return appReducer(state, action);
  }

  // Resets the redux state, keeping only the contentful data.
  return {
    ...appReducer(undefined, action),
    ...(state?.content && { content: { ...state.content } })
  };
};

export default rootReducer;
