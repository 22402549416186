import { EClusterType } from "./Cluster";

export const getMissingDaysIndicatorColor = (missingDaysNr?: number | null): EClusterType =>
  missingDaysNr != null
    ? missingDaysNr <= 2
      ? EClusterType.danger
      : missingDaysNr <= 4
      ? EClusterType.warning
      : EClusterType.default
    : EClusterType.default;
