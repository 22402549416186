import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SelectableCard } from "@cf-design-system/card";
import { Icon } from "@cf-design-system/icon";
import { DetailText, HighlightText, SmallHeading } from "@cf-design-system/typography";
import Routes from "../../routes";
import { getAlarms } from "../../store/alarm/selectors";
import { getCurrentContent } from "../../store/content/selectors";
import { selectSilo } from "../../store/farm/actions";
import { ISilo } from "../../store/farm/types";
import { getMissingDaysIndicatorColor } from "../map/helpers";
import Silo from "./Silo";
import "./siloList.scss";

interface IProps {
  silo: ISilo;
}

const SiloListItem: React.FC<IProps & RouteComponentProps> = ({ history, silo }) => {
  const dispatch = useDispatch();
  const { siloList: content } = useSelector(getCurrentContent);
  const siloAlarms = useSelector(getAlarms)[silo.id];
  const {
    capacityPct,
    feed: { name: feedName },
    lastMeasure,
    missingDaysNr,
    weight
  } = silo;
  const siloWeight = ((weight || 0) / 1000).toFixed(1);
  const lastMeasureWeight = lastMeasure ? ((lastMeasure.weight || 0) / 1000).toFixed(1) : 0;

  const siloData = useMemo(() => {
    const missingDays =
      missingDaysNr != null
        ? `${missingDaysNr} ${`${content.common_daysLeft}`.toLocaleLowerCase()}`
        : content.common_unknownDaysLeft;

    return `${lastMeasureWeight} / ${siloWeight === "0.0" ? "?" : siloWeight} ${
      content.common_tons
    } (${missingDays})`;
  }, [
    content.common_daysLeft,
    content.common_tons,
    content.common_unknownDaysLeft,
    lastMeasureWeight,
    missingDaysNr,
    siloWeight
  ]);

  const alarmsNr = useMemo(
    () => (siloAlarms && siloAlarms.daysLeftAlarms.length + siloAlarms.feedLvlAlarms.length) || 0,
    [siloAlarms]
  );

  const alarmsLabel = useMemo(
    () =>
      alarmsNr
        ? `${alarmsNr} ${`${
            alarmsNr > 1 ? content.common_alarms : content.siloList_alarm
          }`.toLocaleLowerCase()}`
        : content.common_alarms,
    [alarmsNr, content.siloList_alarm, content.common_alarms]
  );

  const handleSiloClick = useCallback(() => {
    dispatch(selectSilo(silo.id));
    history.push(Routes.silo);
  }, [dispatch, history, silo.id]);

  return (
    <SelectableCard key={silo.number} className="silo" onClick={handleSiloClick}>
      <div className="silo-info">
        <div className="silo-info-item">
          <SmallHeading className="silo-info-name">
            {`${`${content.common_silo}`.toLocaleUpperCase()} ${
              typeof silo.number === "number" ? silo.number : ""
            }`}
          </SmallHeading>
          <DetailText className="silo-info-text silo-custom-name">{silo.name}</DetailText>
        </div>
        <div className="silo-info-item">
          <Icon
            frameClassName="silo-info-icon"
            hasFrame
            name="bar-chart"
            fill="var(--color-black)"
          />
          <DetailText className="silo-info-text">{siloData}</DetailText>
        </div>
        <div className="silo-info-item">
          <Icon frameClassName="silo-info-icon" hasFrame name="tag" fill="var(--color-black)" />
          <DetailText className={`silo-info-text ${feedName ? "" : "silo-info-no-feed"}`}>
            {feedName || content.common_feed}
          </DetailText>
        </div>
        <div className="silo-info-item">
          <Icon frameClassName="silo-info-icon" hasFrame name="bell" fill="var(--color-black)" />
          <DetailText className={`silo-info-text ${alarmsNr ? "" : "silo-info-no-alarms"}`}>
            {alarmsLabel}
          </DetailText>
        </div>
      </div>
      <div className="silo-summary">
        <HighlightText className={`silo-volume-pct ${getMissingDaysIndicatorColor(missingDaysNr)}`}>
          {capacityPct}%
        </HighlightText>
        <Silo height="60" silo={silo} width="24" />
      </div>
    </SelectableCard>
  );
};

export default withRouter(SiloListItem);
