import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from "@cf-design-system/button";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { Dropdown } from "@cf-design-system/dropdown";
import { Icon } from "@cf-design-system/icon";
import { TextInput } from "@cf-design-system/textinput";
import { Text, SubHeading } from "@cf-design-system/typography";
import Routes from "../../routes";
import { editAlarms } from "../../store/alarm/actions";
import {
  getEditAlarmError,
  getEditAlarmLoading,
  getSelectedSiloAlarms
} from "../../store/alarm/selectors";
import { AlarmContactType, AlarmType, IAlarm } from "../../store/alarm/types";
import { getCurrentContent } from "../../store/content/selectors";
import { getSelectedSilo, getSelectedSiloId } from "../../store/farm/selectors";
import PageHeader from "../header/PageHeader";
import "./alarms.scss";

const DEFAULT_ALARM: IAlarm = { alarmType: AlarmContactType.SMS, contact: "", countryCode: "+31" };

// TODO Discussion: should this be more generic and accept X types of alarms instead of specifically feed and days?
const Alarms: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { alarms: content } = useSelector(getCurrentContent);
  const selectedSiloId = useSelector(getSelectedSiloId);
  const silo = useSelector(getSelectedSilo);
  const { daysLeft, daysLeftAlarms, feedLvl, feedLvlAlarms } =
    useSelector(getSelectedSiloAlarms) || {};
  const editError = useSelector(getEditAlarmError);
  const editLoading = useSelector(getEditAlarmLoading);
  const [newDaysLeft, setDaysLeft] = useState(daysLeft ?? "");
  const [newDaysLeftAlarms, setDaysLeftAlarms] = useState<IAlarm[]>(
    daysLeftAlarms || [DEFAULT_ALARM]
  );
  const [newFeedLvl, setFeedLvl] = useState(feedLvl ?? "");
  const [newFeedLvlAlarms, setFeedLvlAlarms] = useState<IAlarm[]>(feedLvlAlarms || [DEFAULT_ALARM]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!selectedSiloId) {
      history.replace(Routes.silos);
    }
  }, [history, selectedSiloId]);

  // Update data if state vars change i.e. if api request comes in.
  useEffect(() => {
    setDaysLeft(daysLeft ?? "");
    setDaysLeftAlarms(
      daysLeftAlarms && daysLeftAlarms.length > 0 ? daysLeftAlarms : [DEFAULT_ALARM]
    );
    setFeedLvl(feedLvl ?? "");
    setFeedLvlAlarms(feedLvlAlarms && feedLvlAlarms.length > 0 ? feedLvlAlarms : [DEFAULT_ALARM]);
  }, [
    daysLeft,
    daysLeftAlarms,
    feedLvl,
    feedLvlAlarms,
    setDaysLeft,
    setDaysLeftAlarms,
    setFeedLvl,
    setFeedLvlAlarms
  ]);

  useEffect(() => {
    if (saving && !editLoading) {
      setSaving(false);

      if (!editError) {
        history.push(Routes.silo);
      }
    }
  }, [editError, editLoading, history, saving, setSaving]);

  const handleFeedLvlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setFeedLvl(e.target.value),
    [setFeedLvl]
  );

  const handleDaysLeftChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setDaysLeft(e.target.value),
    [setDaysLeft]
  );

  const handleAlarmChange = useCallback(
    (type: AlarmType, changedField: { [key: string]: string }, index: number) => {
      const setAlarmList = type === AlarmType.FEED_LEVEL ? setFeedLvlAlarms : setDaysLeftAlarms;

      setAlarmList(prevAlarmList => {
        const alarmList = [...prevAlarmList];

        alarmList[index] = (
          changedField.alarmType && changedField.alarmType !== prevAlarmList[index].alarmType
            ? {
                ...changedField,
                contact: "",
                countryCode:
                  changedField.alarmType === AlarmContactType.SMS ? DEFAULT_ALARM.countryCode : ""
              }
            : { ...prevAlarmList[index], ...changedField }
        ) as IAlarm;

        return alarmList;
      });
    },
    [setDaysLeftAlarms, setFeedLvlAlarms]
  );

  const handleFeedLvlAlarmChange = useCallback(
    (changedField: { [key: string]: string }, index: number) =>
      handleAlarmChange(AlarmType.FEED_LEVEL, changedField, index),
    [handleAlarmChange]
  );

  const handleDaysLeftAlarmChange = useCallback(
    (changedField: { [key: string]: string }, index: number) =>
      handleAlarmChange(AlarmType.DAYS_LEFT, changedField, index),
    [handleAlarmChange]
  );

  const handleAlarmDelete = useCallback(
    (type: AlarmType, index: number) => {
      const setAlarmList = type === AlarmType.FEED_LEVEL ? setFeedLvlAlarms : setDaysLeftAlarms;

      setAlarmList(prevAlarmList => {
        const alarmList = prevAlarmList.filter((alarm: IAlarm, i: number) => i !== index);

        return alarmList.length ? alarmList : [DEFAULT_ALARM];
      });
    },
    [setDaysLeftAlarms, setFeedLvlAlarms]
  );

  const handleAddAlarmClick = useCallback(
    (type: AlarmType) => {
      const setAlarmList = type === AlarmType.FEED_LEVEL ? setFeedLvlAlarms : setDaysLeftAlarms;

      setAlarmList(prevAlarmList => [...prevAlarmList, DEFAULT_ALARM]);
    },
    [setDaysLeftAlarms, setFeedLvlAlarms]
  );

  const handleSave = useCallback(() => {
    if (silo) {
      setSaving(true);
      dispatch(
        editAlarms(silo.id, {
          daysLeft: newDaysLeft,
          daysLeftAlarms: newDaysLeftAlarms,
          feedLvl: newFeedLvl,
          feedLvlAlarms: newFeedLvlAlarms
        })
      );
    }
  }, [dispatch, newDaysLeft, newDaysLeftAlarms, newFeedLvl, newFeedLvlAlarms, silo]);

  const handleCancel = useCallback(() => history.push(Routes.silo), [history]);

  const renderAlarmItem = useCallback(
    (
      type: AlarmType,
      { alarmType = AlarmContactType.SMS, contact = "", countryCode = "pt" }: IAlarm = {
        alarmType: AlarmContactType.SMS,
        contact: "",
        countryCode: "pt"
      },
      index: number
    ) => {
      const handleChange =
        type === AlarmType.FEED_LEVEL ? handleFeedLvlAlarmChange : handleDaysLeftAlarmChange;

      const alarmTypeItems = [
        { id: AlarmContactType.EMAIL, icon: "mail", text: content.alarms_sendEmail },
        { id: AlarmContactType.SMS, icon: "message", text: content.alarms_sendSMS }
      ];

      const countryCodeItems = [
        { id: "nl", icon: "flagNl", text: "+31" },
        { id: "pt", icon: "flagPt", text: "+351" },
        { id: "de", icon: "flagDe", text: "+49" },
        { id: "be", icon: "flagBe", text: "+32" }
      ];

      const selectedAlarmType = alarmTypeItems.find(item => item.id === alarmType);

      const countryCodeSelected =
        countryCodeItems.find(item => item.text === countryCode) ?? countryCodeItems[0];

      return (
        <React.Fragment key={index}>
          <span className="alarm-list-item-wrapper">
            <span className="alarm-list-item">
              <span className="alarm-list-item-section">
                <Dropdown
                  className="alarm-type-dropdown"
                  items={alarmTypeItems}
                  headerIcon={selectedAlarmType?.icon}
                  onItemSelected={selectedItem =>
                    handleChange({ alarmType: selectedItem.id }, index)
                  }
                  selectedItem={selectedAlarmType}
                  useParentWidth
                />
                <Text>{content.common_to}</Text>
              </span>
              <span className="alarm-list-item-section">
                {alarmType === AlarmContactType.SMS && (
                  <Dropdown
                    className="alarm-country-code-dropdown"
                    items={countryCodeItems}
                    headerIcon={countryCodeSelected?.icon}
                    onItemSelected={selectedItem =>
                      handleChange({ countryCode: selectedItem.text }, index)
                    }
                    selectedItem={countryCodeSelected}
                    useParentWidth
                  />
                )}
                <TextInput
                  className={alarmType === AlarmContactType.EMAIL ? "alarm-email-input" : ""}
                  onChange={e => handleChange({ contact: e.target.value }, index)}
                  type={alarmType === AlarmContactType.SMS ? "tel" : "email"}
                  value={contact}
                />
              </span>
            </span>
            <DangerButton
              className="alarm-delete-button"
              onClick={() => handleAlarmDelete(type, index)}
            >
              {content.alarms_delete}
            </DangerButton>
          </span>
          <Divider className="alarm-list-item-divider" />
        </React.Fragment>
      );
    },
    [
      content.alarms_delete,
      content.alarms_sendEmail,
      content.alarms_sendSMS,
      content.common_to,
      handleAlarmDelete,
      handleDaysLeftAlarmChange,
      handleFeedLvlAlarmChange
    ]
  );

  return (
    <section className="alarms">
      <PageHeader
        className="alarm-page-header"
        backRoute={Routes.silos}
        title={`Silo ${silo?.number || ""}`}
      />
      <BackgroundCard className="alarms-body">
        <SubHeading>{content.alarms_feedLevelTitle}</SubHeading>
        <div className="alarm-list-header">
          <Text>{content.alarms_siloAt}</Text>
          <div className="alarm-list-header-value">
            <TextInput
              className="alarm-list-header-input"
              max="99.9"
              min="0"
              onChange={handleFeedLvlChange}
              placeholder="00.0"
              step=".1"
              type="number"
              value={newFeedLvl}
            />
            <Text>{content.common_tons}:</Text>
          </div>
        </div>
        <div className="alarm-list">
          {newFeedLvlAlarms.map((alarm: IAlarm, i: number) =>
            renderAlarmItem(AlarmType.FEED_LEVEL, alarm, i)
          )}
          <TertiaryButton
            className="add-alarm-button"
            onClick={() => handleAddAlarmClick(AlarmType.FEED_LEVEL)}
            renderLeftIcon={() => <Icon name="plus" />}
          >
            {content.alarms_addAlarm}
          </TertiaryButton>
        </div>
        <Divider className="alarms-actions-divider" />
        <SubHeading>{content.alarms_daysLeftTitle}</SubHeading>
        <div className="alarm-list-header">
          <Text>{content.alarms_daysLeft}</Text>
          <div className="alarm-list-header-value">
            <TextInput
              className="alarm-list-header-input"
              max="99"
              min="0"
              onChange={handleDaysLeftChange}
              placeholder="0"
              type="number"
              value={newDaysLeft}
            />
            <Text>{`${content.alarms_moreDays}:`}</Text>
          </div>
        </div>
        <div className="alarm-list">
          {newDaysLeftAlarms.map((alarm: IAlarm, i: number) =>
            renderAlarmItem(AlarmType.DAYS_LEFT, alarm, i)
          )}
          <TertiaryButton
            className="add-alarm-button"
            onClick={() => handleAddAlarmClick(AlarmType.DAYS_LEFT)}
            renderLeftIcon={() => <Icon name="plus" />}
          >
            {content.alarms_addAlarm}
          </TertiaryButton>
        </div>
        <Divider className="alarms-actions-divider" />
        <div className="alarms-actions">
          <PrimaryButton
            className="save-button"
            disabled={!newDaysLeft && !newFeedLvl}
            onClick={handleSave}
            type="submit"
          >
            {content.common_save}
          </PrimaryButton>
          <SecondaryButton onClick={handleCancel} type="button">
            {content.common_cancel}
          </SecondaryButton>
        </div>
      </BackgroundCard>
    </section>
  );
};

export default Alarms;
