import produce, { Draft } from "immer";
import { Reducer } from "redux";
import {
  ACCEPT_PRIVACY_POLICY_SUCCESS,
  EDIT_PASSWORD,
  EDIT_PASSWORD_ERROR,
  EDIT_PASSWORD_SUCCESS,
  IUserState,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  STORE_TOKEN,
  UserActionTypes
} from "./types";

const initialState: IUserState = {
  countryCode: "",
  editPasswordError: false,
  editPasswordLoading: false,
  hasLoginError: false,
  hasAcceptedPrivacyPolicy: false,
  resetPasswordError: false,
  resetPasswordLoading: false,
  token: "",
  username: ""
};

const reducer: Reducer<IUserState, UserActionTypes> = (
  state = initialState,
  action: UserActionTypes
) =>
  produce(state, (draft: Draft<IUserState>) => {
    switch (action.type) {
      case ACCEPT_PRIVACY_POLICY_SUCCESS:
        draft.hasAcceptedPrivacyPolicy = true;
        break;

      case EDIT_PASSWORD:
        draft.editPasswordError = false;
        draft.editPasswordLoading = true;
        break;

      case EDIT_PASSWORD_ERROR:
        draft.editPasswordError = true;
        draft.editPasswordLoading = false;
        break;

      case EDIT_PASSWORD_SUCCESS:
        draft.editPasswordError = false;
        draft.editPasswordLoading = false;
        break;

      case LOGIN_ERROR:
        draft.hasLoginError = true;
        break;

      case LOGIN_SUCCESS:
        draft.countryCode = action.payload.user.countryCode;
        draft.hasLoginError = false;
        draft.hasAcceptedPrivacyPolicy = action.payload.user.privacyPolicy;
        draft.username = action.payload.user.username;
        break;

      case LOGOUT_SUCCESS:
        draft.countryCode = "";
        draft.hasAcceptedPrivacyPolicy = false;
        draft.token = "";
        draft.username = "";
        break;

      case RESET_PASSWORD:
        draft.resetPasswordError = false;
        draft.resetPasswordLoading = true;
        break;

      case RESET_PASSWORD_ERROR:
        draft.resetPasswordError = true;
        draft.resetPasswordLoading = false;
        break;

      case RESET_PASSWORD_SUCCESS:
        draft.resetPasswordError = false;
        draft.resetPasswordLoading = false;
        break;

      case STORE_TOKEN:
        draft.token = action.payload.token;
        break;

      default:
        break;
    }
  });

export default reducer;
