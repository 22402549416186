import { call, ForkEffect, put, select, takeLatest } from "redux-saga/effects";
import APIService from "../services/api";
import NotificationsService from "../services/notifications";
import { getCurrentContent } from "../store/content/selectors";
import { IMappedEntries } from "../store/content/types";
import {
  editSiloFeedError,
  editSiloFeedSuccess,
  editSiloInfoError,
  editSiloInfoSuccess,
  requestFarmsError,
  requestSiloListError,
  requestFarmsSuccess,
  requestSiloSuccess,
  requestSiloListSuccess
} from "../store/farm/actions";
import { mapEditedSiloFarms, mapSiloMeasures, mapSilosMissingDays } from "../store/farm/helpers";
import { getFarms as getFarmsSelector } from "../store/farm/selectors";
import {
  EDIT_SILO_FEED,
  EDIT_SILO_INFO,
  IEditSiloFeed,
  IEditSiloInfo,
  IFarm,
  IRequestSilo,
  IRequestSiloList,
  ISilo,
  REQUEST_FARMS,
  REQUEST_SILO,
  REQUEST_SILO_LIST
} from "../store/farm/types";
import { IFeed } from "../store/feed/types";

function* editSiloFeed(action: IEditSiloFeed) {
  const { siloFeed: content } = (yield select(getCurrentContent)) as IMappedEntries;
  const farms = (yield select(getFarmsSelector)) as IFarm[];

  try {
    const silo = (yield call(
      APIService.editSiloFeed,
      action.payload.siloId,
      action.payload.feedId
    )) as ISilo;

    yield put(editSiloFeedSuccess(mapEditedSiloFarms(silo, farms)));

    NotificationsService.addNotification({
      text: content.siloFeed_notificationSuccess
    });
  } catch (error: unknown) {
    yield put(editSiloFeedError());

    NotificationsService.addNotification({
      isError: true,
      text: content.siloFeed_notificationError
    });
  }
}

function* editSiloInfo(action: IEditSiloInfo) {
  const { siloInfo: content } = (yield select(getCurrentContent)) as IMappedEntries;
  const farms = (yield select(getFarmsSelector)) as IFarm[];

  try {
    const silo = (yield call(
      APIService.editSiloInfo,
      action.payload.siloId,
      action.payload.name,
      action.payload.animalId,
      action.payload.animalQuantity
    )) as ISilo;

    const feed = (yield call(
      APIService.editFeed,
      action.payload.siloId,
      action.payload.feedId,
      action.payload.feedName,
      action.payload.feedDensity
    )) as IFeed;

    silo.feed = feed;

    yield put(editSiloInfoSuccess(mapEditedSiloFarms(silo, farms)));
    NotificationsService.addNotification({
      text: content.siloInfo_notificationSuccess
    });
  } catch (error: unknown) {
    yield put(editSiloInfoError());
    NotificationsService.addNotification({
      isError: true,
      text: content.siloInfo_notificationError
    });
  }
}

function* getFarms() {
  try {
    const farms = (yield call(APIService.getFarms)) as IFarm[];

    yield put(requestFarmsSuccess(farms));
  } catch (error: unknown) {
    yield put(requestFarmsError());
  }
}

function* getSiloDetails(action: IRequestSiloList) {
  try {
    const farm = (yield call(APIService.getSiloDetails, action.payload.farmId)) as IFarm;
    yield put(requestSiloListSuccess(mapSilosMissingDays(farm)));
  } catch (error: unknown) {
    yield put(requestSiloListError());
  }
}

function* getSilo(action: IRequestSilo) {
  const { siloDetails: content } = (yield select(getCurrentContent)) as IMappedEntries;
  const farms = (yield select(getFarmsSelector)) as IFarm[];
  const timestamp = Date.now() - action.payload.timePeriod;

  try {
    const silo = (yield call(APIService.getSilo, action.payload.siloId, timestamp)) as ISilo;

    yield put(requestSiloSuccess(mapSiloMeasures(silo, farms)));
  } catch (error: unknown) {
    NotificationsService.addNotification({
      isError: true,
      text: content.siloDetails_notificationError
    });
  }
}

function* farmWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(EDIT_SILO_FEED, editSiloFeed);
  yield takeLatest(EDIT_SILO_INFO, editSiloInfo);
  yield takeLatest(REQUEST_FARMS, getFarms);
  yield takeLatest(REQUEST_SILO_LIST, getSiloDetails);
  yield takeLatest(REQUEST_SILO, getSilo);
}

export default farmWatcher;
