import produce, { Draft } from "immer";
import { Reducer } from "redux";
import {
  ContentActionTypes,
  IContentState,
  REQUEST_CONTENT,
  REQUEST_CONTENT_ERROR,
  REQUEST_CONTENT_SUCCESS,
  SET_LANGUAGE
} from "./types";

const initialState: IContentState = {
  contentError: false,
  contentfulToken: "",
  contentLoading: false,
  currentLanguage: "nl-NL",
  dbTimestamp: null,
  entries: {},
  languages: []
};

const reducer: Reducer<IContentState, ContentActionTypes> = (
  state = initialState,
  action: ContentActionTypes
) =>
  produce(state, (draft: Draft<IContentState>) => {
    switch (action.type) {
      case REQUEST_CONTENT:
        draft.contentError = false;
        draft.contentLoading = true;
        break;

      case REQUEST_CONTENT_SUCCESS:
        draft.contentError = false;
        draft.contentfulToken = action.payload.contentfulToken || draft.contentfulToken;
        draft.contentLoading = false;
        draft.dbTimestamp = action.payload.dbTimestamp;
        draft.entries = action.payload.entries;
        draft.languages = action.payload.languages;
        break;

      case REQUEST_CONTENT_ERROR:
        draft.contentError = true;
        draft.contentLoading = false;
        break;

      case SET_LANGUAGE:
        draft.currentLanguage = action.payload.language;
        break;

      default:
        break;
    }
  });

export default reducer;
