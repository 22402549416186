import { Persistor } from "redux-persist";
import { all, AllEffect, ForkEffect } from "redux-saga/effects";
import alarmWatcher from "./alarm";
import animalWatcher from "./animal";
import contentWatcher from "./content";
import farmWatcher from "./farm";
import feedWatcher from "./feed";
import userWatcher from "./user";

function* rootSaga(
  persistor: Persistor
): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([
    alarmWatcher(),
    animalWatcher(),
    contentWatcher(),
    farmWatcher(),
    feedWatcher(),
    userWatcher(persistor)
  ]);
}

export default rootSaga;
