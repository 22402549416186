import produce, { Draft } from "immer";
import { Reducer } from "redux";
import { FeedActionTypes, IFeedState, REQUEST_FEEDS_ERROR, REQUEST_FEEDS_SUCCESS } from "./types";

const initialState: IFeedState = {
  feeds: []
};

const reducer: Reducer<IFeedState, FeedActionTypes> = (
  state = initialState,
  action: FeedActionTypes
) =>
  produce(state, (draft: Draft<IFeedState>) => {
    switch (action.type) {
      case REQUEST_FEEDS_ERROR:
        draft.feeds = [];
        break;

      case REQUEST_FEEDS_SUCCESS:
        draft.feeds = action.payload.feeds;
        break;

      default:
        break;
    }
  });

export default reducer;
