import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "@cf-design-system/button";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { Dropdown, IItem } from "@cf-design-system/dropdown";
import { TextInput } from "@cf-design-system/textinput";
import { Text, SubHeading } from "@cf-design-system/typography";
import Routes from "../../routes";
import { getAnimals } from "../../store/animal/selectors";
import { IAnimal } from "../../store/animal/types";
import { getCurrentContent } from "../../store/content/selectors";
import { editSiloInfo } from "../../store/farm/actions";
import {
  getEditSiloError,
  getEditSiloLoading,
  getSelectedSilo,
  getSelectedSiloId
} from "../../store/farm/selectors";
import PageHeader from "../header/PageHeader";
import "./siloInfo.scss";

const SiloInfo: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { siloInfo: content } = useSelector(getCurrentContent);
  const selectedSiloId = useSelector(getSelectedSiloId);
  const silo = useSelector(getSelectedSilo);
  const animals = useSelector(getAnimals);
  const editError = useSelector(getEditSiloError);
  const editLoading = useSelector(getEditSiloLoading);
  const [animal, setAnimal] = useState(silo?.animal?.id ?? "");
  const [animalQuantity, setAnimalQuantity] = useState(silo?.animalQuantity ?? undefined);
  const [siloName, setSiloName] = useState(silo?.name ?? "");
  const [feedName, setFeedName] = useState(silo?.feed.name ?? "");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!selectedSiloId) {
      history.push(Routes.silos);
    }
  }, [history, selectedSiloId]);

  useEffect(() => {
    setAnimal(silo?.animal?.id ?? "");
    setAnimalQuantity(silo?.animalQuantity ?? undefined);
    setSiloName(silo?.name ?? "");
    setFeedName(silo?.feed.name ?? "");
  }, [setAnimal, setAnimalQuantity, setFeedName, setSiloName, silo]);

  useEffect(() => {
    if (saving && !editLoading) {
      setSaving(false);

      if (!editError) {
        history.push(Routes.silo);
      }
    }
  }, [editError, editLoading, history, saving, setSaving]);

  const animalItems = useMemo(
    (): IItem[] => [
      {
        id: "",
        text: content.siloInfo_animals
      },
      ...animals.map((animalItem: IAnimal) => ({
        id: animalItem.id,
        text: content[`common_${animalItem.name}`]
      }))
    ],
    [animals, content]
  );

  const selectedAnimal = useMemo(
    () => animalItems.find((animalItem: IItem) => animalItem.id === animal) || animalItems[0],
    [animal, animalItems]
  );

  const handleSiloNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSiloName(e.target.value),
    [setSiloName]
  );

  const handleFeedNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setFeedName(e.target.value),
    [setFeedName]
  );

  const handleAnimalQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setAnimalQuantity(e.target.valueAsNumber ?? undefined),
    [setAnimalQuantity]
  );

  const handleAnimalChange = useCallback((selected: IItem) => setAnimal(selected.id), [setAnimal]);

  const handleCancel = useCallback(() => history.push(Routes.silo), [history]);

  const handleSave = useCallback(() => {
    if (silo) {
      setSaving(true);
      dispatch(
        editSiloInfo(
          silo.id,
          silo.feed.id,
          silo.feed.density,
          siloName,
          feedName,
          animal ?? null,
          animalQuantity ?? null
        )
      );
    }
  }, [animal, animalQuantity, dispatch, feedName, silo, siloName]);

  return (
    <section className="edit-silo-info">
      <PageHeader
        className="edit-silo-info-header"
        backRoute={Routes.silo}
        title={content.common_info}
      />
      <BackgroundCard className="edit-silo-info-body">
        <SubHeading className="title">{content.siloInfo_title}</SubHeading>
        <div className="edit-silo-info-profile">
          <TextInput
            className="edit-silo-info-profile-input"
            label={content.siloInfo_customName}
            onChange={handleSiloNameChange}
            placeholder={content.siloInfo_nickname}
            type="text"
            value={siloName}
          />
        </div>
        <Divider className="edit-silo-info-divider" />
        <SubHeading className="title">{content.common_feed}</SubHeading>
        <div className="edit-silo-info-feed">
          <TextInput
            className="edit-silo-info-feed-input"
            label={content.siloInfo_feedName}
            onChange={handleFeedNameChange}
            placeholder={content.siloInfo_feedName}
            type="text"
            value={feedName}
          />
        </div>
        <Divider className="edit-silo-info-divider" />
        <SubHeading className="title">{content.siloInfo_animalsTitle}</SubHeading>
        <div className="edit-silo-info-animals">
          <TextInput
            className="edit-silo-info-animals-input"
            min="0"
            onChange={handleAnimalQuantityChange}
            placeholder="0"
            type="number"
            value={animalQuantity ?? ""}
          />
          <Dropdown
            className="edit-silo-info-animals-dropdown"
            items={animalItems}
            selectedItem={selectedAnimal}
            onItemSelected={handleAnimalChange}
          />
          <Text>{content.siloInfo_currentlyFeeding}</Text>
        </div>
        <Divider className="edit-silo-info-divider" />
        <div className="actions">
          <PrimaryButton className="save-button" onClick={handleSave} type="submit">
            {content.common_save}
          </PrimaryButton>
          <SecondaryButton onClick={handleCancel} type="button">
            {content.common_cancel}
          </SecondaryButton>
        </div>
      </BackgroundCard>
    </section>
  );
};

export default SiloInfo;
