import { call, ForkEffect, put, select, takeLatest } from "redux-saga/effects";
import APIService from "../services/api";
import NotificationsService from "../services/notifications";
import {
  editAlarmsError,
  editAlarmsSuccess,
  requestAlarmsError,
  requestAlarmsSuccess
} from "../store/alarm/actions";
import { mapAlarms, mapApiAlarms } from "../store/alarm/helpers";
import { EDIT_ALARMS, IApiAlarms, IEditAlarms, REQUEST_ALARMS } from "../store/alarm/types";
import { getCurrentContent } from "../store/content/selectors";
import { IMappedEntries } from "../store/content/types";

function* editAlarms(action: IEditAlarms) {
  const { alarms: content } = (yield select(getCurrentContent)) as IMappedEntries;

  try {
    const mappedAlarms = mapAlarms(action.payload.siloId, action.payload.alarms);

    const { [action.payload.siloId]: alarms } = (yield call(
      APIService.editAlarms,
      mappedAlarms
    )) as IApiAlarms;

    yield put(editAlarmsSuccess(mapApiAlarms({ [action.payload.siloId]: alarms })));

    NotificationsService.addNotification({
      text: content.alarms_notificationSuccess
    });
  } catch (error: unknown) {
    yield put(editAlarmsError());

    NotificationsService.addNotification({
      isError: true,
      text: content.alarms_notificationError
    });
  }
}

function* getAlarms() {
  try {
    const alarms = (yield call(APIService.getAlarms)) as IApiAlarms;

    yield put(requestAlarmsSuccess(mapApiAlarms(alarms)));
  } catch (error: unknown) {
    yield put(requestAlarmsError());
  }
}

function* farmWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(EDIT_ALARMS, editAlarms);
  yield takeLatest(REQUEST_ALARMS, getAlarms);
}

export default farmWatcher;
