import { AnyAction } from "redux";
import { REHYDRATE } from "redux-persist";
import { call, ForkEffect, put, select, takeLatest } from "redux-saga/effects";
import APIService from "../services/api";
import {
  requestContent,
  requestContentError,
  requestContentSuccess
} from "../store/content/actions";
import { mapContentfulEntries } from "../store/content/helpers";
import { getContentSync } from "../store/content/selectors";
import { IApiContent, IApiContentSync } from "../store/content/types";

function* getContent() {
  const { contentfulToken: currentContentfulToken, dbTimestamp: currentDbTimestamp } =
    (yield select(getContentSync)) as IApiContentSync;

  try {
    yield put(requestContent());

    const {
      assets,
      contentfulToken,
      dbTimestamp,
      entries: contentfulEntries
    } = (yield call(
      APIService.getContentfulEntries,
      currentContentfulToken,
      currentDbTimestamp
    )) as IApiContent;

    if (contentfulEntries.length) {
      const { entries, languages } = mapContentfulEntries(assets, contentfulEntries);

      yield put(requestContentSuccess(contentfulToken, dbTimestamp, entries, languages));
    }
  } catch (error: unknown) {
    yield put(requestContentError());
  }
}

function* contentWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    (action: AnyAction) => action.type === REHYDRATE && action.key === "content",
    getContent
  );
}

export default contentWatcher;
