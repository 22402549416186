import { createSelector } from "reselect";
import { AppState } from "..";
import { IAlarms } from "./types";
import { getSelectedSiloId } from "../farm/selectors";

const getAlarm = (state: AppState) => state.alarm;

export const getAlarms: (state: AppState) => IAlarms = state => getAlarm(state).alarms;

export const getEditAlarmError: (state: AppState) => boolean = state =>
  getAlarm(state).editAlarmError;

export const getEditAlarmLoading: (state: AppState) => boolean = state =>
  getAlarm(state).editAlarmLoading;

export const getSelectedSiloAlarms = createSelector(
  getSelectedSiloId,
  getAlarms,
  (selectedSiloId: string, alarms: IAlarms) => alarms[selectedSiloId]
);
