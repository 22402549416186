import {
  EDIT_ALARMS,
  EDIT_ALARMS_ERROR,
  EDIT_ALARMS_SUCCESS,
  IAlarmInfo,
  IAlarms,
  IEditAlarms,
  IEditAlarmsError,
  IEditAlarmsSuccess,
  IRequestAlarms,
  IRequestAlarmsError,
  IRequestAlarmsSuccess,
  REQUEST_ALARMS,
  REQUEST_ALARMS_ERROR,
  REQUEST_ALARMS_SUCCESS
} from "./types";

export const editAlarms = (siloId: string, alarms: IAlarmInfo): IEditAlarms => ({
  type: EDIT_ALARMS,
  payload: {
    alarms,
    siloId
  }
});

export const editAlarmsError = (): IEditAlarmsError => ({ type: EDIT_ALARMS_ERROR });

export const editAlarmsSuccess = (alarms: IAlarms): IEditAlarmsSuccess => ({
  type: EDIT_ALARMS_SUCCESS,
  payload: {
    alarms
  }
});

export const requestAlarms = (): IRequestAlarms => ({ type: REQUEST_ALARMS });

export const requestAlarmsError = (): IRequestAlarmsError => ({ type: REQUEST_ALARMS_ERROR });

export const requestAlarmsSuccess = (alarms: IAlarms): IRequestAlarmsSuccess => ({
  type: REQUEST_ALARMS_SUCCESS,
  payload: {
    alarms
  }
});
