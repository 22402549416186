import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BackgroundCard } from "@cf-design-system/card";
import { Divider } from "@cf-design-system/divider";
import { DetailText, HighlightText, SmallHeading } from "@cf-design-system/typography";
import { getCurrentContent } from "../../store/content/selectors";
import { getFarmOrders } from "../../store/farm/selectors";
import { IOrder } from "../../store/farm/types";
import "./orderHistory.scss";

const OrderHistory: React.FC = () => {
  const { siloList: content } = useSelector(getCurrentContent);
  const orders = useSelector(getFarmOrders);

  const headers = useMemo(
    () => [
      content.siloList_deliveryDate,
      content.siloList_silos,
      content.siloList_amount,
      content.siloList_productName,
      content.siloList_productNumber
    ],
    [content]
  );

  const headerItems = useMemo(
    () =>
      headers.map((header: string) => (
        <th key={header}>
          <SmallHeading>{header}</SmallHeading>
        </th>
      )),
    [headers]
  );

  const orderItems = useMemo(
    () =>
      orders?.map((order: IOrder, index: number) => (
        <tr key={index}>
          <td>
            <DetailText>{order.deliveryDate}</DetailText>
          </td>
          <td>
            <DetailText>{order.rawSiloNumber}</DetailText>
          </td>
          <td>
            <DetailText>{order.weight}</DetailText>
          </td>
          <td>
            <DetailText>{order.productName}</DetailText>
          </td>
          <td>
            <DetailText>{order.productNumber}</DetailText>
          </td>
        </tr>
      )),
    [orders]
  );

  return (
    <BackgroundCard className="order-history">
      {orders && orders.length > 0 ? (
        <>
          <HighlightText className="title">{content.siloList_latestOrders}</HighlightText>
          <table className="table">
            <thead>
              <tr>{headerItems}</tr>
              <tr>
                <td colSpan={headers.length}>
                  <Divider className="header-divider" />
                </td>
              </tr>
            </thead>
            <tbody>{orderItems}</tbody>
          </table>
        </>
      ) : (
        <span className="title">{content.siloList_noOrders}</span>
      )}
    </BackgroundCard>
  );
};

export default OrderHistory;
