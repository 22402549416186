import {
  AlarmContactType,
  ApiAlarmType,
  IAlarm,
  IAlarmInfo,
  IAlarms,
  IApiAlarm,
  IApiAlarms
} from "./types";

const mapApiSiloAlarms: (alarms?: IApiAlarm[]) => IAlarmInfo = alarms =>
  !alarms
    ? {
        daysLeft: "",
        daysLeftAlarms: [],
        feedLvl: "",
        feedLvlAlarms: []
      }
    : alarms.reduce(
        (result: IAlarmInfo, alarm: IApiAlarm) => {
          const phoneNumbers = alarm.phoneNumbers.map(
            (phoneNumber: { countryCode: string; number: string }) => ({
              alarmType: AlarmContactType.SMS,
              contact: phoneNumber.number,
              countryCode: phoneNumber.countryCode
            })
          );

          const emails = alarm.emails.map((email: string) => ({
            alarmType: AlarmContactType.EMAIL,
            contact: email
          }));

          return {
            ...result,
            ...(alarm.type === ApiAlarmType.LEVEL && {
              feedLvl: `${(alarm.value / 1000).toFixed(1)}`,
              feedLvlAlarms: [...phoneNumbers, ...emails]
            }),
            ...(alarm.type === ApiAlarmType.DAYS && {
              daysLeft: `${alarm.value}`,
              daysLeftAlarms: [...phoneNumbers, ...emails]
            })
          };
        },
        { daysLeft: "", daysLeftAlarms: [], feedLvl: "", feedLvlAlarms: [] }
      );

export const mapApiAlarms: (alarms: IApiAlarms) => IAlarms = alarms =>
  Object.keys(alarms).reduce(
    (mappedAlarms: IAlarms, siloId: string) => ({
      ...mappedAlarms,
      [siloId]: mapApiSiloAlarms(alarms[siloId])
    }),
    {}
  );

const mapAlarmsByType: (
  type: ApiAlarmType,
  siloId: string,
  alarmInfo: { alarmList: IAlarm[]; value: string }
) => IApiAlarm | null = (type, siloId, { alarmList, value }) => {
  if (!value) {
    return null;
  }

  const { emails, phoneNumbers } = alarmList.reduce(
    (
      result: { emails: string[]; phoneNumbers: { countryCode: string; number: string }[] },
      alarm: IAlarm
    ) =>
      alarm.alarmType === AlarmContactType.SMS && !!alarm.countryCode && !!alarm.contact
        ? {
            ...result,
            phoneNumbers: [
              ...result.phoneNumbers,
              { countryCode: alarm.countryCode, number: alarm.contact }
            ]
          }
        : alarm.alarmType === AlarmContactType.EMAIL && !!alarm.contact
        ? {
            ...result,
            emails: [...result.emails, alarm.contact]
          }
        : result,
    { emails: [], phoneNumbers: [] }
  );

  return !!emails.length || !!phoneNumbers.length
    ? {
        emails,
        isEmail: !!emails.length,
        isSms: !!phoneNumbers.length,
        phoneNumbers,
        siloId,
        type,
        value:
          type === ApiAlarmType.LEVEL ? Math.round(parseFloat(value) * 10) * 100 : parseInt(value)
      }
    : null;
};

export const mapAlarms: (siloId: string, alarmInfo: IAlarmInfo) => IApiAlarm[] = (
  siloId,
  { daysLeft, daysLeftAlarms, feedLvl, feedLvlAlarms }
) =>
  [
    mapAlarmsByType(ApiAlarmType.DAYS, siloId, { alarmList: daysLeftAlarms, value: daysLeft }),
    mapAlarmsByType(ApiAlarmType.LEVEL, siloId, { alarmList: feedLvlAlarms, value: feedLvl })
  ].filter(Boolean) as IApiAlarm[];
