import {
  IFeed,
  IRequestFeeds,
  IRequestFeedsError,
  IRequestFeedsSuccess,
  REQUEST_FEEDS,
  REQUEST_FEEDS_ERROR,
  REQUEST_FEEDS_SUCCESS
} from "./types";

export const requestFeeds = (): IRequestFeeds => ({ type: REQUEST_FEEDS });

export const requestFeedsError = (): IRequestFeedsError => ({ type: REQUEST_FEEDS_ERROR });

export const requestFeedsSuccess = (feeds: IFeed[]): IRequestFeedsSuccess => ({
  type: REQUEST_FEEDS_SUCCESS,
  payload: {
    feeds
  }
});
