import produce, { Draft } from "immer";
import { Reducer } from "redux";
import {
  AnimalActionTypes,
  IAnimalState,
  REQUEST_ANIMALS_ERROR,
  REQUEST_ANIMALS_SUCCESS
} from "./types";

const initialState: IAnimalState = {
  animals: []
};

const reducer: Reducer<IAnimalState, AnimalActionTypes> = (
  state = initialState,
  action: AnimalActionTypes
) =>
  produce(state, (draft: Draft<IAnimalState>) => {
    switch (action.type) {
      case REQUEST_ANIMALS_ERROR:
        draft.animals = [];
        break;

      case REQUEST_ANIMALS_SUCCESS:
        draft.animals = action.payload.animals;
        break;

      default:
        break;
    }
  });

export default reducer;
