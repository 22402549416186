import {
  ILanguage,
  ILanguageEntries,
  IRequestContent,
  IRequestContentError,
  IRequestContentSuccess,
  ISetLanguage,
  REQUEST_CONTENT,
  REQUEST_CONTENT_ERROR,
  REQUEST_CONTENT_SUCCESS,
  SET_LANGUAGE
} from "./types";

export const requestContent = (): IRequestContent => ({ type: REQUEST_CONTENT });

export const requestContentError = (): IRequestContentError => ({ type: REQUEST_CONTENT_ERROR });

export const requestContentSuccess = (
  contentfulToken: string | null,
  dbTimestamp: number,
  entries: ILanguageEntries,
  languages: ILanguage[]
): IRequestContentSuccess => ({
  type: REQUEST_CONTENT_SUCCESS,
  payload: {
    contentfulToken,
    dbTimestamp,
    entries,
    languages
  }
});

export const setLanguage = (language: string): ISetLanguage => ({
  type: SET_LANGUAGE,
  payload: {
    language
  }
});
