import produce, { Draft } from "immer";
import { Reducer } from "redux";
import {
  AlarmActionTypes,
  EDIT_ALARMS,
  EDIT_ALARMS_ERROR,
  EDIT_ALARMS_SUCCESS,
  IAlarmState,
  REQUEST_ALARMS_ERROR,
  REQUEST_ALARMS_SUCCESS
} from "./types";

const initialState: IAlarmState = {
  alarms: {},
  editAlarmError: false,
  editAlarmLoading: false
};

const reducer: Reducer<IAlarmState, AlarmActionTypes> = (
  state = initialState,
  action: AlarmActionTypes
) =>
  produce(state, (draft: Draft<IAlarmState>) => {
    switch (action.type) {
      case EDIT_ALARMS:
        draft.editAlarmError = false;
        draft.editAlarmLoading = true;
        break;

      case EDIT_ALARMS_ERROR:
        draft.editAlarmError = true;
        draft.editAlarmLoading = false;
        break;

      case EDIT_ALARMS_SUCCESS:
        draft.alarms = {
          ...draft.alarms,
          ...action.payload.alarms
        };
        draft.editAlarmError = false;
        draft.editAlarmLoading = false;
        break;

      case REQUEST_ALARMS_ERROR:
        draft.alarms = {};
        break;

      case REQUEST_ALARMS_SUCCESS:
        draft.alarms = action.payload.alarms;
        break;

      default:
        break;
    }
  });

export default reducer;
