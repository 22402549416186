import { ISilo } from "../farm/types";

export const REQUEST_FEEDS = "feed/REQUEST_FEEDS";
export const REQUEST_FEEDS_ERROR = "feed/REQUEST_FEEDS_ERROR";
export const REQUEST_FEEDS_SUCCESS = "feed/REQUEST_FEEDS_SUCCESS";

export interface IFeed {
  createDateTime: string;
  density: number;
  digitplanName: string;
  id: string;
  name: string;
  productNumber: string;
  serial: string;
  silos: ISilo[] | null;
}

export interface IFeedState {
  feeds: IFeed[];
}

export interface IRequestFeeds {
  type: typeof REQUEST_FEEDS;
}

export interface IRequestFeedsError {
  type: typeof REQUEST_FEEDS_ERROR;
}

export interface IRequestFeedsSuccess {
  type: typeof REQUEST_FEEDS_SUCCESS;
  payload: {
    feeds: IFeed[];
  };
}

export type FeedActionTypes = IRequestFeeds | IRequestFeedsError | IRequestFeedsSuccess;
