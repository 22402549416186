import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import APIService from "../services/api";
import { requestFeedsError, requestFeedsSuccess } from "../store/feed/actions";
import { IFeed, REQUEST_FEEDS } from "../store/feed/types";

function* getFeeds() {
  try {
    const feeds = (yield call(APIService.getFeeds)) as IFeed[];

    yield put(requestFeedsSuccess(feeds));
  } catch (error: unknown) {
    yield put(requestFeedsError());
  }
}

function* feedWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(REQUEST_FEEDS, getFeeds);
}

export default feedWatcher;
