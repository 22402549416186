import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Icon } from "@cf-design-system/icon";
import { Link } from "@cf-design-system/link";
import { DetailText, Text, SubHeading } from "@cf-design-system/typography";
import { getSelectedSiloAlarms } from "../../store/alarm/selectors";
import { AlarmContactType, IAlarm } from "../../store/alarm/types";
import { getCurrentContent } from "../../store/content/selectors";
import Routes from "../../routes";
import "./siloList.scss";

const SiloAlarms: React.FC<RouteComponentProps> = ({ history }) => {
  const { siloDetails: content } = useSelector(getCurrentContent);
  const { daysLeft, daysLeftAlarms, feedLvl, feedLvlAlarms } =
    useSelector(getSelectedSiloAlarms) || {};

  const handleAlarmClick = useCallback(() => {
    history.push(Routes.alarms);
  }, [history]);

  const renderAlarms = useCallback(
    (alarms: IAlarm[]) =>
      alarms.map((alarm: IAlarm, index: number) => (
        <DetailText className="silo-alarm-contacts-item" key={index}>
          {`${(alarm.alarmType === AlarmContactType.SMS && alarm.countryCode) || ""} ${
            alarm.contact
          }`}
        </DetailText>
      )),
    []
  );

  return (
    <Text>
      <div className="silo-alarms">
        <div className="silo-alarms-header">
          <SubHeading>{content.common_alarms}</SubHeading>
          <Link onClick={handleAlarmClick} className="edit-alarms-btn">
            {content.siloDetails_editAlarms}
          </Link>
        </div>
        {feedLvl || daysLeft ? (
          <>
            {feedLvl && (
              <div className="silo-alarms-section">
                <div className="silo-alarm">
                  <Icon
                    frameClassName="silo-alarm-icon"
                    frameColor="#fbfafa"
                    hasFrame
                    name="weight"
                  />
                  <Text>
                    {feedLvl} {content.common_tons}
                  </Text>
                </div>
                <div className="silo-alarm-contacts">{renderAlarms(feedLvlAlarms)}</div>
              </div>
            )}
            {daysLeft && (
              <div className="silo-alarms-section">
                <div className="silo-alarm">
                  <Icon
                    frameClassName="silo-alarm-icon"
                    frameColor="#fbfafa"
                    hasFrame
                    name="calendar"
                  />
                  <Text>
                    {daysLeft} {content.common_daysLeft.toLocaleLowerCase()}
                  </Text>
                </div>
                <div className="silo-alarm-contacts">{renderAlarms(daysLeftAlarms)}</div>
              </div>
            )}
          </>
        ) : (
          <div className="silo-alarms-section">
            <Text className="no-alarms-text">{content.siloDetails_noAlarms}</Text>
          </div>
        )}
      </div>
    </Text>
  );
};

export default withRouter(SiloAlarms);
