import React, { useMemo } from "react";
import { DetailText, SmallHeading } from "@cf-design-system/typography";
import { ISilo } from "../../store/farm/types";
import "./map.scss";

interface IProps {
  content: Record<string, string>;
  silo: ISilo;
}

const MarkerPopup: React.FC<IProps> = ({ content, silo }) => {
  const { feed, lastMeasure, missingDaysNr, name, number, weight } = silo;

  const siloWeight = useMemo(() => (weight / 1000).toFixed(1), [weight]);

  const lastMeasureWeight = useMemo(
    () => ((lastMeasure?.weight ?? 0) / 1000).toFixed(1),
    [lastMeasure]
  );

  const missingDays = useMemo(
    () =>
      missingDaysNr != null
        ? `${missingDaysNr} ${content.common_daysLeft.toLocaleLowerCase()}`
        : content.common_unknownDaysLeft,
    [content, missingDaysNr]
  );

  return (
    <div className="marker-popup">
      <div className="marker-popup-title">
        <SmallHeading className="marker-popup-main-title">
          {`${`${content.common_silo}`.toLocaleUpperCase()} ${number}`}
        </SmallHeading>
        <DetailText className="marker-popup-subtitle">{name}</DetailText>
      </div>
      <DetailText>
        {lastMeasureWeight} / {siloWeight} {content.common_tons}
      </DetailText>
      <DetailText>{missingDays}</DetailText>
      {feed.name && <DetailText className="marker-popup-feed">{feed.name}</DetailText>}
    </div>
  );
};

export default MarkerPopup;
