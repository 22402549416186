export const REQUEST_ANIMALS = "animal/REQUEST_ANIMALS";
export const REQUEST_ANIMALS_ERROR = "animal/REQUEST_ANIMALS_ERROR";
export const REQUEST_ANIMALS_SUCCESS = "animal/REQUEST_ANIMALS_SUCCESS";

export interface IAnimal {
  createDateTime: string;
  id: string;
  name: string;
}

export interface IAnimalState {
  animals: IAnimal[];
}

export interface IRequestAnimals {
  type: typeof REQUEST_ANIMALS;
}

export interface IRequestAnimalsError {
  type: typeof REQUEST_ANIMALS_ERROR;
}

export interface IRequestAnimalsSuccess {
  type: typeof REQUEST_ANIMALS_SUCCESS;
  payload: {
    animals: IAnimal[];
  };
}

export type AnimalActionTypes = IRequestAnimals | IRequestAnimalsError | IRequestAnimalsSuccess;
