import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "@cf-design-system/button";
import "@cf-design-system/icon";
import { ErrorTextInput, TextInput } from "@cf-design-system/textinput";
import Routes from "../../routes";
import Logo from "../../assets/icons/LogoLogin.svg";
import { getCurrentContent } from "../../store/content/selectors";
import {
  login,
  recoverPassword,
  resetPassword,
  validateResetToken
} from "../../store/user/actions";
import {
  getHasLoginError,
  getResetPasswordError,
  getResetPasswordLoading
} from "../../store/user/selectors";
import "./login.scss";

const Login: React.FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { login: content } = useSelector(getCurrentContent);
  const hasLoginError = useSelector(getHasLoginError);
  const resetError = useSelector(getResetPasswordError);
  const resetLoading = useSelector(getResetPasswordLoading);
  const query = new URLSearchParams(location.search);
  const recoverToken = query.get("recoverToken") as string;
  const email = query.get("email") as string;
  const [username, setUsername] = useState(email || "");
  const [password, setPassword] = useState("");
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [confirmationPasswordError, setConfirmationPasswordError] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);

  useEffect(() => {
    if (location.pathname === Routes.recoverPassword) {
      if (recoverToken && email) {
        dispatch(validateResetToken(recoverToken));
      } else {
        history.push(Routes.home);
      }
    }
  }, [dispatch, email, history, location.pathname, recoverToken]);

  useEffect(() => {
    if (savingPassword && !resetLoading) {
      setSavingPassword(false);

      if (!resetError) {
        history.push(Routes.home);
      }
    }
  }, [history, resetError, resetLoading, savingPassword, setSavingPassword]);

  const handleUsernameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value),
    [setUsername]
  );

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    [setPassword]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(login(username, password));
    },
    [dispatch, password, username]
  );

  const handleRecoverPassword = useCallback(
    () => setShowRecoverPassword(!showRecoverPassword),
    [showRecoverPassword, setShowRecoverPassword]
  );

  const handleRecoverSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(recoverPassword(username));
    },
    [dispatch, username]
  );

  const handleNewPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (confirmationPasswordError) {
        setConfirmationPasswordError(false);
      }

      setNewPassword(e.target.value);
    },
    [confirmationPasswordError, setConfirmationPasswordError, setNewPassword]
  );

  const handleConfirmationPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (confirmationPasswordError) {
        setConfirmationPasswordError(false);
      }

      setConfirmationPassword(e.target.value);
    },
    [confirmationPasswordError, setConfirmationPassword, setConfirmationPasswordError]
  );

  const handleChangeSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (newPassword !== confirmationPassword) {
        setConfirmationPasswordError(true);
      } else {
        dispatch(resetPassword(recoverToken, newPassword));
        setSavingPassword(true);
      }
    },
    [
      confirmationPassword,
      dispatch,
      newPassword,
      recoverToken,
      setConfirmationPasswordError,
      setSavingPassword
    ]
  );

  const handleCancel = useCallback(() => history.push(Routes.home), [history]);

  return (
    <section className="login">
      <img alt="logo" className="logo" src={Logo} />
      {recoverToken ? (
        <form className="form" onSubmit={handleChangeSubmit}>
          <TextInput
            className="input-username"
            disabled
            label={content.common_email}
            placeholder={content.common_email}
            type="email"
            value={username}
          />
          <ErrorTextInput
            className="input-password"
            error={confirmationPasswordError}
            label={content.common_newPassword}
            onChange={handleNewPasswordChange}
            placeholder={content.common_newPassword}
            required
            type="password"
            value={newPassword}
          />
          <ErrorTextInput
            className="input-password"
            error={confirmationPasswordError && content.common_matchPasswords}
            label={content.common_confirmPassword}
            onChange={handleConfirmationPasswordChange}
            placeholder={content.common_confirmPassword}
            required
            type="password"
            value={confirmationPassword}
          />
          <div className="actions">
            <PrimaryButton className="primary-button" type="submit">
              {content.common_changePassword}
            </PrimaryButton>
            <SecondaryButton onClick={handleCancel} type="button">
              {content.common_cancel}
            </SecondaryButton>
          </div>
        </form>
      ) : !showRecoverPassword ? (
        <form className="form" onSubmit={handleSubmit}>
          <TextInput
            className="input-username"
            label={content.common_email}
            onChange={handleUsernameChange}
            placeholder={content.common_email}
            type="email"
            value={username}
          />
          <ErrorTextInput
            className="input-password"
            error={hasLoginError && content.login_credentialsError}
            label={content.login_password}
            onChange={handlePasswordChange}
            placeholder={content.login_password}
            type="password"
            value={password}
          />
          <div className="actions">
            <PrimaryButton className="primary-button" type="submit">
              {content.login_button}
            </PrimaryButton>
            <SecondaryButton onClick={handleRecoverPassword} type="button">
              {content.login_recoverPassword}
            </SecondaryButton>
          </div>
        </form>
      ) : (
        <form className="form" onSubmit={handleRecoverSubmit}>
          <TextInput
            className="input-username"
            label={content.common_email}
            onChange={handleUsernameChange}
            placeholder={content.common_email}
            type="email"
            value={username}
          />
          <div className="actions">
            <PrimaryButton className="primary-button" type="submit">
              {content.login_recoverPassword}
            </PrimaryButton>
            <SecondaryButton onClick={handleRecoverPassword} type="button">
              {content.common_cancel}
            </SecondaryButton>
          </div>
        </form>
      )}
    </section>
  );
};

export default Login;
