import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import APIService from "../services/api";
import { requestAnimalsError, requestAnimalsSuccess } from "../store/animal/actions";
import { IAnimal, REQUEST_ANIMALS } from "../store/animal/types";

function* getAnimals() {
  try {
    const animals = (yield call(APIService.getAnimals)) as IAnimal[];

    yield put(requestAnimalsSuccess(animals));
  } catch (error: unknown) {
    yield put(requestAnimalsError());
  }
}

function* animalWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(REQUEST_ANIMALS, getAnimals);
}

export default animalWatcher;
