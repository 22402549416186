const Routes = {
  // Public Routes.
  privacyPolicy: "/privacy-policy",
  recoverPassword: "/recover-password",

  // Private Routes.
  account: "/account",
  alarms: "/alarms",
  home: "/",
  language: "/language",
  silo: "/silo",
  siloInfo: "/silo-info",
  silos: "/silos"
};

export default Routes;
