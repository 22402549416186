import { createSelector } from "reselect";
import { AppState } from "..";
import {
  IApiContentSync,
  IContentState,
  ILanguage,
  ILanguageEntries,
  IMappedEntries
} from "./types";

const getContent = (state: AppState) => state.content;

const getContentEntries = (state: AppState) => getContent(state).entries;

export const getContentError: (state: AppState) => boolean = state =>
  getContent(state).contentError;

export const getContentLoaded: (state: AppState) => boolean = state =>
  !!getContent(state).contentfulToken;

export const getContentLoading: (state: AppState) => boolean = state =>
  getContent(state).contentLoading;

export const getCurrentLanguage: (state: AppState) => string = state =>
  getContent(state).currentLanguage;

export const getLanguages: (state: AppState) => ILanguage[] = state => getContent(state).languages;

export const getContentSync: (state: AppState) => IApiContentSync = createSelector(
  getContent,
  ({ contentfulToken, dbTimestamp }: IContentState) => ({ contentfulToken, dbTimestamp })
);

export const getCurrentContent: (state: AppState) => IMappedEntries = createSelector(
  getContentEntries,
  getCurrentLanguage,
  (entries: ILanguageEntries, language: string) => ({
    account: {},
    alarms: {},
    farmList: {},
    footer: {},
    header: {},
    language: {},
    login: {},
    privacyPolicy: {},
    siloDetails: {},
    siloFeed: {},
    siloInfo: {},
    siloList: {},
    siloMenu: {},
    ...entries[language]
  })
);
