import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { SelectableCard } from "@cf-design-system/card";
import { useWindowSize } from "@cf-design-system/hooks";
import { Icon } from "@cf-design-system/icon";
import { Tab, TabGroup, ITabGroupRef } from "@cf-design-system/tab";
import { HighlightText, SmallHeading, SubHeading, Text } from "@cf-design-system/typography";
import { TextInput } from "@cf-design-system/textinput";
import Routes from "../../routes";
import { getCurrentContent } from "../../store/content/selectors";
import { selectFarm } from "../../store/farm/actions";
import { getSelectedFarmId, getSortedFarms } from "../../store/farm/selectors";
import { IFarm } from "../../store/farm/types";
import Map from "../map/Map";
import PageHeader from "../header/PageHeader";
import "./farmList.scss";

const FarmList: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const selectedFarmId = useSelector(getSelectedFarmId);
  const farms = useSelector(getSortedFarms);
  const { farmList: content } = useSelector(getCurrentContent);
  const tabGroupRef = useRef<ITabGroupRef>(null);
  const [mapHeight, setMapHeight] = useState<number>(0);
  const [searchFarm, setSearchFarm] = useState("");
  const [selectedTab, setSelectedTab] = useState<string | number>(0);

  const handleFarmClick = useCallback(
    (farm: IFarm) => {
      dispatch(selectFarm(farm.id));
      history.push(Routes.silos);
    },
    [dispatch, history]
  );

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFarm(event?.target?.value);
  }, []);

  const handleTabChange = (newTab: string | number) => {
    setSelectedTab(newTab);
  };

  // Reset selected farm.
  useEffect(() => {
    if (selectedFarmId) {
      dispatch(selectFarm(""));
    }
  }, [dispatch, selectedFarmId]);

  // Calculate map height based on remaining free viewport space.
  // Leaflet requires a fixed height to be set on the map element.
  useEffect(() => {
    if (tabGroupRef.current) {
      // "Manually" get TabGroup's DOM element as tabGroupRef cannot be used.
      const tabGroupEl = document.body.getElementsByClassName("farms-tab-group")[0];
      const bottomMargin = 48;
      const topMargin = 32;

      // Take margins around map element into account.
      setMapHeight(
        window.innerHeight - tabGroupEl?.getBoundingClientRect().bottom - topMargin - bottomMargin
      );
    }
  }, [farms, tabGroupRef, windowSize]);

  const filteredFarms = useMemo(() => {
    return farms.filter(val => {
      return val.name?.toLocaleLowerCase().includes(searchFarm?.toLocaleLowerCase());
    });
  }, [farms, searchFarm]);

  const renderFarm = useCallback(
    (farm: IFarm) => (
      <SelectableCard key={farm.name} className="farm" onClick={() => handleFarmClick(farm)}>
        <div className="farm-title">
          <SubHeading>{farm.name}</SubHeading>
          {farm.address && <Text className="farm-address">{farm.address}</Text>}
        </div>
        <div className="farm-silos">
          <SmallHeading className="farm-silos-title">
            {`${content.farmList_sensors}`.toLocaleUpperCase()}
          </SmallHeading>
          <div className="farm-silos-icon-wrapper">
            <Icon fill="var(--color-black)" frameClassName="farm-silos-icon" name="silo" hasFrame />
            <HighlightText>{farm.siloQuantity}</HighlightText>
          </div>
        </div>
      </SelectableCard>
    ),
    [content.farmList_sensors, handleFarmClick]
  );

  return (
    <section className="farms">
      <PageHeader className="farms-header" title={content.farmList_title} />
      {farms.length > 1 ? (
        <div className="farms-list">
          <TabGroup className="farms-tab-group" ref={tabGroupRef} onTabClick={handleTabChange}>
            <Tab label={content.farmList_title}>
              <div className="farms-body">
                {filteredFarms.map((farm: IFarm) => renderFarm(farm))}
              </div>
            </Tab>
            <Tab label={content.farmList_map}>
              <Map farms={farms} height={mapHeight} />
            </Tab>
          </TabGroup>
          {selectedTab === 0 && (
            <TextInput
              name="searchFarm"
              type="text"
              placeholder={content.farmList_searchPlaceHolder}
              onChange={handleSearchChange}
              value={searchFarm}
              className="farms-body-search"
              suffixIcon="search"
            />
          )}
        </div>
      ) : (
        <div className="farms-body">{filteredFarms.map((farm: IFarm) => renderFarm(farm))}</div>
      )}
    </section>
  );
};

export default FarmList;
