import { store } from "../store/setup";
import { IApiAlarm, IApiAlarms } from "../store/alarm/types";
import { IAnimal } from "../store/animal/types";
import { IApiContent } from "../store/content/types";
import { IFarm, ISilo } from "../store/farm/types";
import { IFeed } from "../store/feed/types";
import { logout } from "../store/user/actions";
import { IUser } from "../store/user/types";

type Middleware = (response: Response, jsonResponseBody: unknown) => unknown;

export interface IAPIService {
  acceptPrivacyPolicy: () => Promise<void>;
  editAlarms: (alarms: IApiAlarm[]) => Promise<IApiAlarms>;
  editFeed: (siloId: string, feedId: string, name: string, density: number) => Promise<IFeed>;
  editPassword: (currentPassword: string, newPassword: string) => Promise<void>;
  editSiloFeed: (siloId: string, feedId: string) => Promise<ISilo>;
  editSiloInfo: (
    siloId: string,
    name: string,
    animalId: string,
    animalQuantity: number
  ) => Promise<ISilo>;
  getAlarms: () => Promise<IApiAlarms>;
  getAnimals: () => Promise<IAnimal[]>;
  getContentfulEntries: (token: string, dbTimestamp: number | null) => Promise<IApiContent>;
  getFarms: () => Promise<IFarm[]>;
  getSiloDetails: (farmId: string) => Promise<IFarm>;
  getFeeds: () => Promise<IFeed[]>;
  getSilo: (siloId: string, timePeriod: number) => Promise<ISilo>;
  getUser: () => Promise<IUser>;
  login: (username: string, password: string) => Promise<string>;
  recoverPassword: (username: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
  validateResetToken: (token: string) => Promise<void>;
}

class APIService implements IAPIService {
  private instance: {
    delete: <T>(
      url: string,
      body?: unknown,
      middlewares?: Middleware[],
      contentType?: string,
      timeout?: number
    ) => Promise<T>;
    get: <T>(url: string) => Promise<T>;
    post: <T>(
      url: string,
      body?: unknown,
      middlewares?: Middleware[],
      contentType?: string,
      timeout?: number
    ) => Promise<T>;
    put: <T>(
      url: string,
      body?: unknown,
      middlewares?: Middleware[],
      contentType?: string,
      timeout?: number
    ) => Promise<T>;
  };

  constructor() {
    const baseTimeout = 15000;
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const baseRequest = async <T>(
      url: string,
      body?: unknown,
      method: RequestInit["method"] = "GET",
      middlewares: Middleware[] = [],
      contentType = "application/json",
      timeout = baseTimeout
    ) => {
      const authToken = store.getState().user.token;
      const init: RequestInit = {
        method,
        ...(body
          ? {
              body:
                contentType === "application/json"
                  ? JSON.stringify(body)
                  : (body as RequestInit["body"])
            }
          : {}),
        headers: {
          Accept: "application/json",
          "Content-Type": contentType,
          ...(authToken && { Authorization: `Bearer ${authToken}` })
        }
      };

      const response = await Promise.race([
        fetch(baseURL + url, init),
        new Promise<never>((_, reject) => setTimeout(() => reject(new Error("Timeout")), timeout))
      ]);

      const textResponseBody = await response.text();

      const jsonResponseBody = textResponseBody
        ? (JSON.parse(textResponseBody) as unknown)
        : undefined;

      if (!response.ok) {
        if (!!authToken && [401].includes(response.status)) {
          store.dispatch(logout());
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        throw new Error((jsonResponseBody as any)?.error ?? response.statusText);
      }

      return middlewares.reduce(
        (jsonResultBody: unknown, middleware: Middleware) => middleware(response, jsonResultBody),
        jsonResponseBody
      ) as T;
    };

    this.instance = {
      delete: <T>(
        url: string,
        body?: unknown,
        middlewares?: Middleware[],
        contentType?: string,
        timeout?: number
      ) => baseRequest<T>(url, body, "DELETE", middlewares, contentType, timeout),
      get: <T>(url: string, middlewares?: Middleware[], contentType?: string, timeout?: number) =>
        baseRequest<T>(url, undefined, "GET", middlewares, contentType, timeout),
      post: <T>(
        url: string,
        body?: unknown,
        middlewares?: Middleware[],
        contentType?: string,
        timeout?: number
      ) => baseRequest<T>(url, body, "POST", middlewares, contentType, timeout),
      put: <T>(
        url: string,
        body?: unknown,
        middlewares?: Middleware[],
        contentType?: string,
        timeout?: number
      ) => baseRequest<T>(url, body, "PUT", middlewares, contentType, timeout)
    };
  }

  acceptPrivacyPolicy = (): Promise<void> => this.instance.post("/applicationUser/privacypolicy");

  editAlarms = (alarms: IApiAlarm[]): Promise<IApiAlarms> =>
    this.instance.post("/applicationUser/alarm", {
      alarms
    });

  editFeed = (siloId: string, feedId: string, name: string, density: number): Promise<IFeed> =>
    this.instance.put(`/feed/${feedId}`, {
      density,
      name,
      siloId
    });

  editPassword = (currentPassword: string, newPassword: string): Promise<void> =>
    this.instance.put("/applicationUser/editpassword", {
      currentPassword,
      newPassword
    });

  editSiloFeed = (siloId: string, feedId: string): Promise<ISilo> =>
    this.instance.put(`/silo/${siloId}/feed/${feedId}`);

  editSiloInfo = (
    siloId: string,
    name: string,
    animalId: string | null,
    animalQuantity: number | null
  ): Promise<ISilo> =>
    this.instance.put(`/silo/${siloId}`, {
      animalId,
      animalQuantity,
      name
    });

  getAlarms = (): Promise<IApiAlarms> => this.instance.get("/applicationUser/alarm");

  getAnimals = (): Promise<IAnimal[]> => this.instance.get("/animal/all");

  getContentfulEntries = (token: string, dbTimestamp: number | null): Promise<IApiContent> =>
    this.instance.get(
      `/translation/all?contentfulToken=${token}&dbTimestamp=${dbTimestamp || ""}&environmentId=${
        process.env.REACT_APP_CONTENTFUL_ENV
      }`
    );

  getFarms = (): Promise<IFarm[]> => this.instance.get("/farm/info");

  getSiloDetails = (farmId: string): Promise<IFarm> => this.instance.get(`/farm/${farmId}/silo`);

  getFeeds = (): Promise<IFeed[]> => this.instance.get("/feed/all");

  getSilo = (siloId: string, timePeriod: number): Promise<ISilo> =>
    this.instance.get(`/silo/${siloId}?timestamp=${timePeriod}`);

  getUser = (): Promise<IUser> => this.instance.get("/applicationUser/me");

  login = (username: string, password: string): Promise<string> => {
    const setAuthToken = (response: Response) => {
      const authToken = response.headers.get("authorization")?.replace("Bearer ", "");

      if (!authToken) {
        throw new Error("Invalid authorization token");
      } else {
        return authToken;
      }
    };

    return this.instance.post("/login", { username, password }, [setAuthToken]);
  };

  recoverPassword = (username: string): Promise<void> =>
    this.instance.post("/applicationUser/sendpasswordresetemail", { username });

  validateResetToken = (token: string): Promise<void> =>
    this.instance.post("/applicationUser/validateresetpassword", { token });

  resetPassword = (token: string, password: string): Promise<void> =>
    this.instance.post("/applicationUser/resetpassword", { token, password });
}

export default new APIService();
