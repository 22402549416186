import {
  ACCEPT_PRIVACY_POLICY,
  ACCEPT_PRIVACY_POLICY_ERROR,
  ACCEPT_PRIVACY_POLICY_SUCCESS,
  EDIT_PASSWORD,
  EDIT_PASSWORD_ERROR,
  EDIT_PASSWORD_SUCCESS,
  IAcceptPrivacyPolicy,
  IAcceptPrivacyPolicyError,
  IAcceptPrivacyPolicySuccess,
  IEditPassword,
  IEditPasswordError,
  IEditPasswordSuccess,
  ILogin,
  ILoginError,
  ILoginSuccess,
  ILogout,
  ILogoutSuccess,
  IRecoverPassword,
  IRequestUser,
  IResetPassword,
  IResetPasswordError,
  IResetPasswordSuccess,
  IStoreToken,
  IUser,
  IValidateResetToken,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  RECOVER_PASSWORD,
  REQUEST_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  STORE_TOKEN,
  VALIDATE_RESET_TOKEN
} from "./types";

export const acceptPrivacyPolicy = (): IAcceptPrivacyPolicy => ({ type: ACCEPT_PRIVACY_POLICY });

export const acceptPrivacyPolicyError = (): IAcceptPrivacyPolicyError => ({
  type: ACCEPT_PRIVACY_POLICY_ERROR
});

export const acceptPrivacyPolicySuccess = (): IAcceptPrivacyPolicySuccess => ({
  type: ACCEPT_PRIVACY_POLICY_SUCCESS
});

export const editPassword = (currentPassword: string, newPassword: string): IEditPassword => ({
  type: EDIT_PASSWORD,
  payload: {
    currentPassword,
    newPassword
  }
});

export const editPasswordError = (): IEditPasswordError => ({ type: EDIT_PASSWORD_ERROR });

export const editPasswordSuccess = (): IEditPasswordSuccess => ({ type: EDIT_PASSWORD_SUCCESS });

export const login = (username: string, password: string): ILogin => ({
  type: LOGIN,
  payload: {
    password,
    username
  }
});

export const loginError = (): ILoginError => ({ type: LOGIN_ERROR });

export const loginSuccess = (user: IUser): ILoginSuccess => ({
  type: LOGIN_SUCCESS,
  payload: {
    user
  }
});

export const logout = (): ILogout => ({ type: LOGOUT });

export const logoutSuccess = (): ILogoutSuccess => ({ type: LOGOUT_SUCCESS });

export const recoverPassword = (username: string): IRecoverPassword => ({
  type: RECOVER_PASSWORD,
  payload: {
    username
  }
});

export const requestUser = (): IRequestUser => ({ type: REQUEST_USER });

export const resetPassword = (token: string, password: string): IResetPassword => ({
  type: RESET_PASSWORD,
  payload: {
    password,
    token
  }
});

export const resetPasswordError = (): IResetPasswordError => ({ type: RESET_PASSWORD_ERROR });

export const resetPasswordSuccess = (): IResetPasswordSuccess => ({ type: RESET_PASSWORD_SUCCESS });

export const storeToken = (token: string): IStoreToken => ({
  type: STORE_TOKEN,
  payload: {
    token
  }
});

export const validateResetToken = (token: string): IValidateResetToken => ({
  type: VALIDATE_RESET_TOKEN,
  payload: {
    token
  }
});
