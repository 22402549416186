import produce, { Draft } from "immer";
import { Reducer } from "redux";
import {
  EDIT_SILO_FEED_SUCCESS,
  EDIT_SILO_INFO,
  EDIT_SILO_INFO_ERROR,
  EDIT_SILO_INFO_SUCCESS,
  FarmActionTypes,
  IFarmState,
  REQUEST_FARMS_ERROR,
  REQUEST_FARMS_SUCCESS,
  REQUEST_SILO_LIST,
  REQUEST_SILO_LIST_ERROR,
  REQUEST_SILO_LIST_SUCCESS,
  REQUEST_SILO_SUCCESS,
  SELECT_FARM,
  SELECT_SILO
} from "./types";

const initialState: IFarmState = {
  editSiloError: false,
  editSiloLoading: false,
  requestSiloListError: false,
  farms: [],
  selectedFarmId: "",
  selectedSiloId: ""
};

const reducer: Reducer<IFarmState, FarmActionTypes> = (
  state = initialState,
  action: FarmActionTypes
) =>
  produce(state, (draft: Draft<IFarmState>) => {
    switch (action.type) {
      case EDIT_SILO_FEED_SUCCESS:
        draft.farms = action.payload.farms;
        break;

      case EDIT_SILO_INFO:
        draft.editSiloError = false;
        draft.editSiloLoading = true;
        break;

      case EDIT_SILO_INFO_ERROR:
        draft.editSiloError = true;
        draft.editSiloLoading = false;
        break;

      case EDIT_SILO_INFO_SUCCESS:
        draft.editSiloError = false;
        draft.editSiloLoading = false;
        draft.farms = action.payload.farms;
        break;

      case REQUEST_FARMS_ERROR:
        draft.farms = [];
        draft.selectedFarmId = "";
        draft.selectedSiloId = "";
        break;

      case REQUEST_FARMS_SUCCESS:
        draft.farms = action.payload.farms;
        break;

      case REQUEST_SILO_SUCCESS:
        draft.farms = action.payload.farms;
        break;

      case SELECT_FARM:
        draft.selectedFarmId = action.payload.farmId;
        draft.selectedSiloId = "";
        break;

      case SELECT_SILO:
        draft.selectedSiloId = action.payload.siloId;
        break;

      case REQUEST_SILO_LIST:
        draft.requestSiloListError = false;
        break;

      case REQUEST_SILO_LIST_ERROR:
        draft.requestSiloListError = true;
        break;

      case REQUEST_SILO_LIST_SUCCESS:
        const farmIndex = draft.farms.findIndex(farm => farm.id === action.payload.farm.id);
        draft.farms[farmIndex] = action.payload.farm;
        break;

      default:
        break;
    }
  });

export default reducer;
