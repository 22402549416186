import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Routes from "../../routes";

interface IScrollPositions {
  [key: string]: number;
}

const ScrollToTop: React.FC<RouteComponentProps> = ({ history, location: { pathname } }) => {
  const scrollingElement = document.getElementById("root");
  const [scrollPositions, setScrollPositions] = useState<IScrollPositions>({});
  const [lastPath, setLastPath] = useState<string>(pathname);

  useEffect(() => {
    if (scrollingElement) {
      const unlisten = history.listen(nextLocation => {
        let newPositions = { ...scrollPositions };

        if (lastPath === Routes.home) {
          newPositions = { [lastPath]: scrollingElement.scrollTop };
        } else if (lastPath === Routes.silos) {
          newPositions = { ...scrollPositions, [lastPath]: scrollingElement.scrollTop };
        }

        setScrollPositions(newPositions);
        setLastPath(nextLocation.pathname);
        setTimeout(() => {
          const state = nextLocation?.state as { resetScroll?: boolean };

          scrollingElement.scrollTo(
            0,
            state?.resetScroll ? 0 : newPositions[nextLocation.pathname] || 0
          );
        });
      });

      return () => unlisten();
    }

    return undefined;
  }, [history, lastPath, scrollPositions, scrollingElement]);

  return null;
};

export default withRouter(ScrollToTop);
