import { createSelector } from "reselect";
import { AppState } from "..";
import { IFarm, IOrder, ISilo } from "./types";
import { DEFAULT_LANG } from "../content/types";
import { getFeeds } from "../feed/selectors";
import { IFeed } from "../feed/types";

const getFarm = (state: AppState) => state.farm;

export const getEditSiloError: (state: AppState) => boolean = state => getFarm(state).editSiloError;

export const getEditSiloLoading: (state: AppState) => boolean = state =>
  getFarm(state).editSiloLoading;

export const getFarms: (state: AppState) => IFarm[] = state => getFarm(state).farms;

export const getSelectedFarmId: (state: AppState) => string = state =>
  getFarm(state).selectedFarmId;

export const getSiloListError: (state: AppState) => boolean = state =>
  getFarm(state).requestSiloListError;

export const getSelectedSiloId: (state: AppState) => string = state =>
  getFarm(state).selectedSiloId;

export const getSelectedFarm = createSelector(
  getFarms,
  getSelectedFarmId,
  (farms: IFarm[], selectedFarmId: string) => {
    const selectedFarm = farms.find((farm: IFarm) => farm.id === selectedFarmId);

    if (!selectedFarm || !selectedFarm?.silos) {
      return selectedFarm;
    }

    return {
      ...selectedFarm,
      silos: [...selectedFarm?.silos].sort((siloA: ISilo, siloB: ISilo) => {
        const siloANumber = parseInt(siloA?.number?.split(",")[0]) ?? 0;
        const siloBNumber = parseInt(siloB?.number?.split(",")[0]) ?? 0;

        return siloANumber < siloBNumber ? -1 : 1;
      })
    };
  }
);

export const getFarmOrders = createSelector(
  getFeeds,
  getSelectedFarm,
  (feeds: IFeed[], farm?: IFarm) =>
    farm?.orders.map((order: IOrder) => {
      const { name: productName = "-", productNumber = "-" } =
        feeds.find((feed: IFeed) => feed.id === order.feedId) || {};

      const deliveryDate = new Date(order.deliveryDate).toLocaleDateString(DEFAULT_LANG, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });

      return {
        ...order,
        deliveryDate,
        productName,
        productNumber
      };
    })
);

export const getSelectedSilo = createSelector(
  getSelectedSiloId,
  getSelectedFarm,
  (selectedSiloId: string, farm?: IFarm) =>
    farm?.silos?.find((silo: ISilo) => silo.id === selectedSiloId)
);

export const getSortedFarms = createSelector(getFarms, (farms: IFarm[]) =>
  [...farms].sort((farmA: IFarm, farmB: IFarm) =>
    farmA.name.toLowerCase() < farmB.name.toLowerCase() ? -1 : 1
  )
);
