import {
  IAnimal,
  IRequestAnimals,
  IRequestAnimalsError,
  IRequestAnimalsSuccess,
  REQUEST_ANIMALS,
  REQUEST_ANIMALS_ERROR,
  REQUEST_ANIMALS_SUCCESS
} from "./types";

export const requestAnimals = (): IRequestAnimals => ({ type: REQUEST_ANIMALS });

export const requestAnimalsError = (): IRequestAnimalsError => ({ type: REQUEST_ANIMALS_ERROR });

export const requestAnimalsSuccess = (animals: IAnimal[]): IRequestAnimalsSuccess => ({
  type: REQUEST_ANIMALS_SUCCESS,
  payload: {
    animals
  }
});
