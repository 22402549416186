import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { renderToString } from "react-dom/server";
import Leaflet from "leaflet";
import "leaflet.markercluster";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "@cf-design-system/tokens";
import { SmallHeading } from "@cf-design-system/typography";
import { getCurrentContent } from "../../store/content/selectors";
import { IFarm } from "../../store/farm/types";
import { getMissingDaysIndicatorColor } from "./helpers";
import Cluster, { EClusterType } from "./Cluster";
import Legend from "./Legend";
import Marker from "./Marker";
import MarkerPopup from "./MarkerPopup";
import "./map.scss";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZGlvZ28tbWVuZGVzIiwiYSI6ImNraGMzMmFuajA3OGMycW4xM29pa2k2a2wifQ.2i-xpie2BqWOg04NoDvRpQ";

interface IProps {
  height?: number;
  farms?: IFarm[];
}

const Map: React.FC<IProps> = ({ farms, height = 400 }) => {
  const { farmList: content } = useSelector(getCurrentContent);
  const [map, setMap] = useState<Leaflet.Map>();
  const [showFarmLabels, setShowFarmLabels] = useState(false);

  const renderCluster = useCallback((cluster: Leaflet.MarkerCluster) => {
    const markers = cluster.getAllChildMarkers();
    let type = EClusterType.default;

    for (let i = 0; i < markers.length - 1; i++) {
      const markerClassname = markers[i].options.icon?.options.className;

      if (markerClassname === EClusterType.danger) {
        type = EClusterType.danger;

        break;
      }

      if (!type && markerClassname === EClusterType.warning) {
        type = EClusterType.warning;
      }
    }

    // Component must be wrapped with ThemeProvider.
    return Leaflet.divIcon({
      html: renderToString(
        <ThemeProvider theme={defaultTheme}>
          <Cluster type={type} label={markers.length} />
        </ThemeProvider>
      ),
      iconSize: Leaflet.point(54, 54)
    });
  }, []);

  const setLegend = useCallback(() => {
    if (map) {
      const legend = new Leaflet.Control({ position: "topright" });

      legend.onAdd = () => {
        const legendEl = document.createElement("div");

        legendEl.innerHTML = renderToString(
          <ThemeProvider theme={defaultTheme}>
            <Legend content={content} />
          </ThemeProvider>
        );

        return legendEl;
      };

      legend.addTo(map);
    }
  }, [content, map]);

  // Init map only after anchor div has been rendered.
  useEffect(() => {
    const leafletMap = Leaflet.map("map-id", { zoomControl: false });

    Leaflet.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        accessToken: MAPBOX_ACCESS_TOKEN,
        attribution:
          "Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>, Imagery © <a href='https://www.mapbox.com/'>Mapbox</a>",
        id: "miguelslpymetis/ckhmbfakn0yae19o11v3usi5m",
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1
      }
    ).addTo(leafletMap);

    setMap(leafletMap);
  }, []);

  useEffect(() => {
    const latitudes: { max?: number; min?: number } = {};
    const longitudes: { max?: number; min?: number } = {};

    if (map) {
      const markers = Leaflet.markerClusterGroup({
        iconCreateFunction: renderCluster,
        showCoverageOnHover: false,
        spiderfyDistanceMultiplier: 2.5,
        spiderLegPolylineOptions: { opacity: 0 }
      });

      farms?.forEach(farm => {
        farm.silos.forEach(silo => {
          const { missingDaysNr } = silo;
          const latitude = Number.parseFloat(silo?.latitude || "0");
          const longitude = Number.parseFloat(silo?.longitude || "0");

          if (!latitudes.max || latitudes.max < latitude) {
            latitudes.max = latitude;
          }

          if (!latitudes.min || latitudes.min > latitude) {
            latitudes.min = latitude;
          }

          if (!longitudes.max || longitudes.max < longitude) {
            longitudes.max = longitude;
          }

          if (!longitudes.min || longitudes.min > longitude) {
            longitudes.min = longitude;
          }

          // Component must be wrapped with ThemeProvider.
          const myIcon = Leaflet.divIcon({
            className: getMissingDaysIndicatorColor(missingDaysNr),
            html: renderToString(
              <ThemeProvider theme={defaultTheme}>
                <Marker silo={silo} />
              </ThemeProvider>
            ),
            iconAnchor: [0, 78],
            popupAnchor: [6, -78]
          });

          // Add custom silo marker for each silo of each farm to cluster group.
          // Component must be wrapped with ThemeProvider.
          markers.addLayer(
            Leaflet.marker([latitude, longitude], { icon: myIcon }).bindPopup(
              renderToString(
                <ThemeProvider theme={defaultTheme}>
                  <MarkerPopup content={content} silo={silo} />
                </ThemeProvider>
              )
            )
          );
        });

        map.addLayer(
          Leaflet.marker(
            [Number.parseFloat(farm.silos[0].latitude), Number.parseFloat(farm.silos[0].longitude)],
            {
              icon: Leaflet.divIcon({
                className: "farm-label",
                html: renderToString(
                  <ThemeProvider theme={defaultTheme}>
                    <SmallHeading>{farm.name}</SmallHeading>
                  </ThemeProvider>
                ),
                iconAnchor: [100, farm.silos.length === 1 ? 0 : -30],
                iconSize: Leaflet.point(200, 50)
              })
            }
          )
        );
      });

      setLegend();

      // Add cluster group to map.
      map.addLayer(markers);

      // Zoom in to area with markers.
      map.fitBounds([
        [latitudes.min ?? 0, longitudes.min ?? 0],
        [latitudes.max ?? 0, longitudes.max ?? 0]
      ]);
    }
  }, [content, farms, map, renderCluster, setLegend]);

  // Show farm labels on the map only past a certain zoom level.
  useEffect(() => {
    map?.on("zoom", () => {
      const showLabels = map.getZoom() >= 9;

      if (showLabels !== showFarmLabels) {
        setShowFarmLabels(showLabels);
      }
    });
  }, [map, showFarmLabels]);

  return (
    <div className={`map ${showFarmLabels ? "detailed" : ""}`}>
      <div id="map-id" style={{ height }} />
    </div>
  );
};

export default Map;
