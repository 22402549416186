import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { Dropdown, IItem } from "@cf-design-system/dropdown";
import { useCheckWindowSize } from "@cf-design-system/hooks";
import { DetailText, H2 } from "@cf-design-system/typography";
import { getSelectedSiloAlarms } from "../../store/alarm/selectors";
import { requestSilo } from "../../store/farm/actions";
import { ISilo, ISiloDayConsumptionData } from "../../store/farm/types";
import { getCurrentContent } from "../../store/content/selectors";
import { getChartData } from "./helpers";
import "./siloList.scss";

export const SEVEN_DAYS_MS = 1000 * 60 * 60 * 24 * 7;
const FOUR_WEEKS_MS = SEVEN_DAYS_MS * 4;
const THREE_MONTHS_MS = FOUR_WEEKS_MS * 3;
const FILLED_THRESHOLD = 2;

enum MAX_X_AXIS_TICK_AMOUNT {
  DESKTOP = 22,
  MOBILE = 12
}

enum X_AXIS_LABEL_ROTATION_THRESHOLD {
  DESKTOP = 15,
  MOBILE = 6
}

interface IProps {
  chartData: ISiloDayConsumptionData[];
  fillData?: ISiloDayConsumptionData;
  silo?: ISilo;
}

// TODO Test loose weekend days filtering.
const getWeekendAnnotations = (chartData: ISiloDayConsumptionData[]) => {
  const weekendDays = chartData
    .filter((dataPoint: ISiloDayConsumptionData) => {
      const weekDay = dataPoint.date.getDay();

      // Keep saturdays (6) and sundays (0) only.
      return weekDay === 0 || weekDay === 6;
    })
    .filter(
      (
        dataPoint: ISiloDayConsumptionData,
        index: number,
        dataPoints: ISiloDayConsumptionData[]
      ) => {
        // Remove loose weekend day.
        if (dataPoints.length <= 1) {
          return false;
        }

        // Remove loose Sunday at the beginning of data point array.
        if (index === 0 && dataPoint.date.getDay() === 0) {
          return false;
        }

        // Remove loose Saturday at the beginning of data point array.
        if (index === dataPoints.length - 1 && dataPoint.date.getDay() === 6) {
          return false;
        }

        return true;
      }
    );

  const annotations = [];

  // Map weekend day pairs to chart annotations.
  for (let i = 0; i < weekendDays.length - 1; i += 2) {
    annotations.push({
      x: weekendDays[i].dateString,
      x2: weekendDays[i + 1].dateString,
      strokeDashArray: 0
    });
  }

  return annotations;
};

const SiloConsumptionChart: React.FC<IProps> = ({ chartData, fillData, silo }) => {
  const dispatch = useDispatch();
  const isMobile = useCheckWindowSize(900);
  const { daysLeft, feedLvl } = useSelector(getSelectedSiloAlarms) || {};
  const { siloDetails: content } = useSelector(getCurrentContent);
  const [filledDaysAgoNr, setFilledDaysAgoNr] = useState<string | null>(null);
  const [timePeriod, setTimePeriod] = useState({
    id: `${SEVEN_DAYS_MS}`,
    text: content.siloDetails_timePeriod_7days
  });

  const siloWeight = useMemo(() => Math.round((silo?.weight || 0) / 100) / 10, [silo]);

  const predictedChartData = useMemo(() => {
    if (silo) {
      const predicted = getChartData(silo.predictedMeasures);

      // If feed is predicted to end today, remove prediction related info from chart.
      return predicted.length > 1 ? predicted : [];
    }

    return [];
  }, [silo]);

  // Remove duplicate "today" date.
  const allChartData = useMemo(
    () => [...chartData, ...[...predictedChartData].splice(1, predictedChartData.length - 1)],
    [chartData, predictedChartData]
  );

  const weekendAnnotations = useMemo(() => getWeekendAnnotations(allChartData), [allChartData]);

  const annotationsXAxis = useMemo(
    () =>
      daysLeft
        ? [
            {
              x: [...chartData, ...predictedChartData][
                chartData?.length - 1 + (predictedChartData?.length - 1) - parseInt(daysLeft)
              ]?.dateString,
              strokeDashArray: 0,
              borderColor: "#FFAA7A",
              label: {
                borderColor: "#FFAA7A",
                offsetY: -8,
                orientation: "horizontal",
                style: {
                  fontSize: "10px",
                  color: "#fff",
                  background: "#FFAA7A"
                },
                text: `${daysLeft} ${`${content.common_daysLeft}`.toLocaleLowerCase()}`
              }
            },
            ...weekendAnnotations
          ]
        : weekendAnnotations,
    [chartData, content.common_daysLeft, daysLeft, predictedChartData, weekendAnnotations]
  );

  const annotationsYAxis = useMemo(
    () =>
      feedLvl
        ? [
            {
              y: feedLvl,
              strokeDashArray: 0,
              borderColor: "#FFAA7A",
              label: {
                borderColor: "#FFAA7A",
                offsetY: -1,
                style: {
                  color: "#fff",
                  background: "#FFAA7A"
                },
                text: `${feedLvl} ${content.common_tons} ${content.common_left}`
              }
            }
          ]
        : [],
    [content.common_left, content.common_tons, feedLvl]
  );

  const xAxisTickAmount = useMemo(() => {
    let i = 1;
    let tickAmount = Math.round(allChartData.length);

    while (
      tickAmount > (isMobile ? MAX_X_AXIS_TICK_AMOUNT.MOBILE : MAX_X_AXIS_TICK_AMOUNT.DESKTOP)
    ) {
      i++;
      tickAmount = Math.round(allChartData.length / i);
    }

    return tickAmount;
  }, [allChartData, isMobile]);

  const isDateInNextTwoMonths = useCallback((date: Date) => {
    const twoMonths = new Date();

    twoMonths.setDate(twoMonths.getDate() + 60);

    return date <= twoMonths;
  }, []);

  const options = useMemo(
    () => ({
      annotations: {
        points: [
          !!predictedChartData.length &&
            isDateInNextTwoMonths(predictedChartData[predictedChartData.length - 1].date) && {
              x: predictedChartData[predictedChartData.length - 1].dateString,
              y: 0,
              strokeDashArray: 0,
              marker: {
                size: 8,
                fillColor: "#fff",
                strokeColor: "#FF4560",
                radius: 2
              },
              label: {
                borderColor: "#FF4560",
                offsetY: 0,
                style: {
                  color: "#fff",
                  background: "#FF4560"
                },
                text: content.siloDetails_expectedEnd
              }
            },
          {
            x: chartData[chartData.length - 1]?.dateString,
            y: Math.round(chartData[chartData.length - 1]?.weight / 100) / 10,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "black",
              radius: 2
            },
            label: {
              borderColor: "black",
              offsetY:
                chartData[chartData.length - 1]?.dateString === fillData?.dateString ? 45 : 0,
              style: {
                color: "#fff",
                background: "black"
              },
              text: content.common_today
            }
          },
          fillData && {
            x: fillData.dateString,
            y: Math.round(fillData.weight / 100) / 10,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "#276BAF",
              radius: 2
            },
            label: {
              borderColor: "#276BAF",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#276BAF"
              },
              text: `${Math.round(fillData.weight / 100) / 10} ${content.common_tons} - ${
                fillData.timeString
              }`
            }
          }
        ],
        xaxis: annotationsXAxis,
        yaxis: annotationsYAxis
      },
      chart: {
        animations: {
          enabled: false
        },
        fontFamily: "SourceSansPro, Helvetica, Arial, sans-serif",
        id: "basic-bar",
        toolbar: {
          show: false
        },
        width: "700",
        zoom: {
          enabled: false
        }
      },
      grid: {
        padding: {
          right: 40
        }
      },
      legend: {
        show: false
      },
      stroke: {
        dashArray: [0, 8]
      },
      theme: {
        monochrome: {
          enabled: true,
          color: "#000000",
          shadeTo: "light",
          shadeIntensity: 1
        }
      },
      markers: {
        showNullDataPoints: false
      },
      xaxis: {
        categories: allChartData.map((measure: ISiloDayConsumptionData) => measure.dateString),
        labels: {
          rotate:
            xAxisTickAmount >
            (isMobile
              ? X_AXIS_LABEL_ROTATION_THRESHOLD.MOBILE
              : X_AXIS_LABEL_ROTATION_THRESHOLD.DESKTOP)
              ? -45
              : 0
        },
        tickAmount: xAxisTickAmount,
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: (value: number | null | undefined) =>
            value != null ? `${value.toFixed(value === 0 ? 0 : 1)} ${content.common_tons}` : null
        },
        min: 0,
        max: (maxMeasure: number) => Math.max(maxMeasure, siloWeight),
        tickAmount: 5
      },
      tooltip: {
        x: {
          formatter: (index: number) =>
            `${allChartData[index - 1]?.dateString} - ${allChartData[index - 1]?.timeString}`
        }
      }
    }),
    [
      allChartData,
      annotationsXAxis,
      annotationsYAxis,
      chartData,
      content.common_today,
      content.common_tons,
      content.siloDetails_expectedEnd,
      fillData,
      isDateInNextTwoMonths,
      isMobile,
      predictedChartData,
      siloWeight,
      xAxisTickAmount
    ]
  );

  const limitedPredictedChartData = useMemo(
    () =>
      predictedChartData.filter((measure: ISiloDayConsumptionData) =>
        isDateInNextTwoMonths(measure.date)
      ),
    [isDateInNextTwoMonths, predictedChartData]
  );

  const series = useMemo(
    () => [
      {
        name: content.common_feed,
        data: chartData.map(
          (measure: ISiloDayConsumptionData) => Math.round(measure.weight / 100) / 10
        )
      },
      {
        name: content.siloDetails_predictedFeed,
        data: [
          ...new Array<null>(chartData.length ? chartData.length - 1 : 0).fill(null),
          ...limitedPredictedChartData.map(
            (measure: ISiloDayConsumptionData) => Math.round(measure.weight / 100) / 10 ?? 0
          )
        ]
      }
    ],
    [chartData, content.common_feed, content.siloDetails_predictedFeed, limitedPredictedChartData]
  );

  const timePeriodItems = useMemo(
    () => [
      { id: `${SEVEN_DAYS_MS}`, text: content.siloDetails_timePeriod_7days },
      { id: `${FOUR_WEEKS_MS}`, text: content.siloDetails_timePeriod_4weeks },
      { id: `${THREE_MONTHS_MS}`, text: content.siloDetails_timePeriod_3months }
    ],
    [content]
  );

  useEffect(() => {
    if (!filledDaysAgoNr) {
      for (let i = chartData.length - 1; i > 0; i--) {
        if (
          Math.round(chartData[i].weight / 100) / 10 >
          Math.round(chartData[i - 1].weight / 100) / 10 + FILLED_THRESHOLD
        ) {
          setFilledDaysAgoNr(`${chartData.length - i - 1}`);

          break;
        }
      }
    }
  }, [chartData, filledDaysAgoNr]);

  const handleTimePeriodChange = useCallback(
    (selectedTimePeriod: IItem) => {
      if (selectedTimePeriod.id !== timePeriod.id) {
        setTimePeriod(selectedTimePeriod);

        if (silo) {
          dispatch(requestSilo(silo.id, Number.parseInt(selectedTimePeriod.id)));
        }
      }
    },
    [dispatch, silo, timePeriod.id]
  );

  return (
    <div className="silo-chart-wrapper">
      <div className="silo-chart-header">
        <div className="silo-chart-title">
          <H2>{content.siloDetails_siloCycle}</H2>
          {/* {filledDaysAgoNr && (
            <Text className="silo-chart-info">
              {`${content.siloDetails_siloFilled}`.replace("%days%", filledDaysAgoNr)}
            </Text>
          )} */}
        </div>
        <Dropdown
          className="time-period-dropdown"
          items={timePeriodItems}
          onItemSelected={handleTimePeriodChange}
          selectedItem={timePeriod}
          useParentWidth
        />
      </div>
      <div className="silo-chart">
        <Chart options={options} series={series} type="line" width="100%" />
      </div>
      <div className="silo-chart-legend">
        <div className="silo-chart-legend-item">
          <div className="silo-chart-legend-color delivery" />
          <DetailText>{content.siloDetails_delivery}</DetailText>
        </div>
        <div className="silo-chart-legend-item">
          <div className="silo-chart-legend-color weekends" />
          <DetailText>{content.siloDetails_weekends}</DetailText>
        </div>
      </div>
    </div>
  );
};

export default SiloConsumptionChart;
